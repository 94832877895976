@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

/* font code */
@font-face {
	font-family: 'OpenSansRegular';
	src: url('/assets/fonts/OpenSansRegular.eot');
	src: local('/assets/fonts/OpenSansRegular'), url('src/assets/fonts/OpenSansRegular.woff') format('woff'), url('src/assets/fonts/OpenSansRegular.ttf') format('truetype');
}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_16804">Text with this font applied</p> */
.fontsforweb_fontid_16804 {
	font-family: 'OpenSansRegular' !important;
}
a.disabled{
  pointer-events: none;
  color: #707070;

}

.clearleft{
  clear: left !important;
}
section {
  margin: 0 16px !important;
}
.pad0{
  padding: 0%;
}
body {
  font-family: 'OpenSansRegular' !important;
  font-size: 14px;
  color: #292B2C;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: #eeeeee;
}
.mat-checkbox-layout .mat-checkbox-label, 
.mat-radio-label-content{font-family: 'OpenSansRegular' !important;}
body a {
  color: #0062cc;
  text-decoration: none;
}

body .pi {
  font-size: unset;
}

body:has(.p-sidebar-mask){
  overflow: hidden;
}


.mat-body, .mat-body-1,
.mat-typography{
  font-family: 'OpenSansRegular' !important;
}

.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}
.mb-60 {
  margin-bottom: 60px;
}

.mar0{
margin:0;
}
body .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: #fff;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #ecbc2b !important;
}
h4.title {
  font-size: 24px !important;
  line-height: 30px !important;
  letter-spacing: 0.5px !important;
  font-family: Aileron-Heavy !important;
  color: #a9820f !important;
  margin-top: 0;
  margin-bottom: 36px;
  text-transform: uppercase;
}
.title1{
  font-size: 18px !important;
  line-height: 30px !important;
  letter-spacing: 0.5px !important;
  font-family: Aileron-Light !important;
  color: #333 !important;
  margin-top: 0px;
  margin-bottom: 0px;
}
.title2 {
  background: #ac830f;
  font-size: 16px !important;
  line-height: 30px !important;
  letter-spacing: 0.5px !important;
  font-family: Aileron-SemiBold;
  color: #fff !important;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 4px 10px;
  border-radius: 4px 4px 0 0;
}
::ng-deep .line1{
  width: 24%;
  margin-top: 0;
  padding: 0;
  margin-bottom: 18px;
}
/*---- table -----*/
.role-mapping-table{
  width: 50%;
}
body .ui-table .ui-table-tbody > tr > td {
  min-height: 25px !important;
  height: 46px !important;
}
::ng-deep body .ui-table .ui-table-thead > tr > th {
  padding: 13px 0 !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
  background-color: #c6a442 !important;
  font-size: 14px !important;
  font-family: Aileron-Bold;
}
::ng-deep body .ui-table .ui-table-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c6a4429e;
  transition: box-shadow 0.2s;
  padding: 6px 8px !important;
  font-size: 13px !important;
  font-family: Aileron-SemiBold !important;
}

/*--- border radius ---*/
::ng-deep input, textarea .ui-corner-all {
  border-radius: 4px !important;
}

/*--- dropdown label ---*/
body .ui-dropdown .ui-dropdown-label {
  padding: 9px;
  font-size: 12px;
  color: #343434;
}

input::placeholder{
  color: #a9a9a9 !important;
}

/* Top Menu CSS Ends */
@media (min-width: 1025px) {
  .layout-wrapper.menu-layout-static .layout-main, .layout-wrapper.menu-layout-static .layout-footer {
      margin-left: 235px;
  }
  .layout-wrapper.menu-layout-static.layout-menu-static-inactive .layout-main, .layout-wrapper.menu-layout-static.layout-menu-static-inactive .layout-footer {
    margin-left: 0px;
  }
  }

label {
  font-size: 14px !important;
  line-height: 30px;
  color:rgba(0, 0, 0, 0.85);
}

/*--- search ----*/
.search_bth {
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
  transition: border-color 0.2s, box-shadow 0.2s;
  font-size: 13px !important;
  color: #333 !important;
  box-shadow: 0 2px 2px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23) !important;
  min-height: 40px;
}
.search_bth::placeholder{
  color: #a9a9a9 !important;
}
.pi-search:before {
  content: "\e908";
  font-size: 14px;
}
.ui-button-warn{
  margin: 0;
  color: #ffffff;
  background-color: #393939;
  border: 1px solid #393939;
  font-size: 14px;
  transition: background-color 0.2s, box-shadow 0.2s;
  box-shadow: 0 5px 5px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23);
}

/*-- add buttom --*/
.add_bth_a {
  text-align: right;
  position: absolute;
  right: 28px;
  min-width: 100px;
  width: 145px;
}
::ng-deep .add_bth {
  margin: 0;
  color: #343434;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  font-size: 13px;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 4px;
  height: 40px;
  width: 140px;
   font-family: Aileron-Bold;
  min-height: 40px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23) !important;
}
.add_bth:hover {
  margin: 0;
  color: #343434 !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}
::ng-deep body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #ab830f;
  background-color: #ac830f;
  color: #ffffff;
}
::ng-deep body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #b68b0e;
  background-color: #ba8f0f;
  color: #ffffff;
}
/*--- paginator ----*/
.paginator_h{
  text-align: center !important;
  margin: 20px auto !important;
  color: #000 !important;
  display: block;
  width: 300px;
}
.mtb-24{
  margin: 20px 0  !important;
}
.mt-24{
  margin-top: 24px  !important;;
}
.mt-5{
  margin-top:5px !important;
}
.mt-14{
  margin-top:14px !important;
}
.mt-15{
  margin-top:15px !important;
}
.mt_30{
  margin-top:-30px !important;
}

/******************Menu Transition CSS********************/
.full-width-screen,
.inbox-outlet {
	-webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
}
.full-width-screen .position-end {
	left: 0;
	-webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  width: 100% !important;
}
/* .p-toast .p-toast-message.p-toast-message-info{background: #000 !important; color:#fff !important;} */

.slide-in > .vertical-menu,
.slide-in > .left-sidebar {
	-webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
}
.slide-out > .vertical-menu,
.slide-out > .left-sidebar {
	-webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	margin-left: -216px;
	left: 0;
}



/*-- paginator ---*/
::ng-deep body .ui-paginator {
  background-color: #d7c853 !important;
  border: 1px solid #b78d0f !important;
}
::ng-deep body .ui-paginator .ui-paginator-first, body .ui-paginator .ui-paginator-prev, body .ui-paginator .ui-paginator-next, body .ui-paginator .ui-paginator-last {
  color: #000000 !important;
  background: #d7c853 !important;
}
::ng-deep .pi-step-backward:before {
  content: "\e916";
  color: #000 !important;
}
::ng-deep .pi-caret-left:before {
  content: "\e904";
  color: #000 !important;
}
::ng-deep .pi-step-forward:before {
  content: "\e917" !important;
  color: #000 !important;
}
::ng-deep .pi-caret-right:before {
  content: "\e905";
  color: #000 !important;
}
/*--- MultiSelector ---*/
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  background-color: #e9f6fc;
  color: #023655;
}
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #023655;
}

body .ui-multiselect {
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
  transition: border-color 0.2s, box-shadow 0.2s;
  font-size: 12px !important;
  color: #343434 !important;
  font-family: Aileron-Bold !important;
  box-shadow: 0 2px 2px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23) !important;
  min-height: 30px;
  height: 36px;
  line-height: 24px;
}
.ui-multiselect .ui-multiselect-trigger .ui-multiselect-trigger-icon {
  background-color: #b98e0c !important;
  width: 40px !important;
  color: #ffffff !important;
  height: 36px !important;
  position: absolute !important;
  right: 0px !important;
  line-height: 36px !important;
  border-radius: 4px !important;
  top: 5px;
  left: -9px;
}

/*---- save and cancel button ----*/
.save_bth{
  margin-right: 10px !important;
  background: #b98e0c !important;
  color: #fff !important;
  padding: 3px 26px !important;
  margin-left: 8px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-family: Aileron-Regular;
}
.cancel_bth{
  background: #fe0000 !important;
  color: #fff !important;
  padding: 3px 20px !important;
  margin-left: 8px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-family: Aileron-Regular;
}
.prev_bth{
  background: #4a4a4a !important;
  color: #fff !important;
  padding: 3px 20px !important;
  margin-left: 8px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-family: Aileron-Regular;

}
input::placeholder{
  color: #a9a9a9 !important;
  font-size: 14px;
}
textarea::placeholder{
color: #a9a9a9 !important;
font-size: 14px;
}
/*-- bth and input ----*/
body .ui-dropdown {
  background: #ffffff !important;
  border: 1px solid #ffffff !important;
  transition: border-color 0.2s, box-shadow 0.2s;
  font-size: 12px !important;
  color: #343434 !important;
  font-family: Aileron-Bold !important;
  box-shadow: 0 2px 2px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23) !important;
  min-height: 30px;
  height: 41px;
  width:100%;
}
.ui-fluid .ui-calendar {
  width: 100%;
  box-shadow: 0 2px 2px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23) !important;
}
::ng-deep body .ui-inputtext {
  font-size: 14px !important;
  color: #333333 !important;
  background: #ffffff !important;
  padding: 8px 10px !important;
  border: 1px solid #ffffff !important;
  transition: border-color 0.2s, box-shadow 0.2s !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  min-height: 30px;
  height: 38px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23) !important;
}
body .ui-inputtext:enabled:focus:not(.ui-state-error) {
    box-shadow: 0 0 0 0.2em #fffdd0  !important;
  border-color: #fffdd0 !important;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #b98e0e !important;
  background-color: #b98e0e !important;
  color: #ffffff !important;
}
body .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover {
  border-color: #af860f !important;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #ba8f0f !important;
  background-color: #ae850f !important;
  color: #ffffff !important;
}
body .ui-label-focus {
  box-shadow: none !important;
}
body .ui-chkbox .ui-chkbox-box.ui-state-focus{
 box-shadow: none;
 border-color: #ba8f0f !important;
}

/*--- radio btn ---*/
::ng-deep body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #c0930e !important;
  background-color: #b78c0e !important;
  color: #ffffff;
}
::ng-deep body .ui-radiobutton .ui-radiobutton-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #c0930e !important;
  background-color: #c0930e !important;
  color: #ffffff;
}
::ng-deep body .ui-inputtext, ::ng-deep body .ui-dropdown {
  border-radius: 4px !important;
}
::ng-deep body .ui-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  box-shadow: 0 0 0 0.2em #b98e0c;
}
::ng-deep body .ui-button:enabled:hover {
  background-color: #b98e0c !important;
  color: #ffffff;
  border-color: #ffffff !important;
}
.ui-calendar {
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
}
/*--- Responsive code ---------*/
@media screen and (max-width: 768px)
{


.table-responsive {
  width: 100%;
  margin: 0 auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: none;
}
/* .custom-tabs:before,
.booking-tabs-menu:before{
  content: '';
  background: url(assets/images/swap-left-to-right.png) no-repeat;
  display: block;
  width: 40px;
  height: 26px;
  background-size: auto;
  position: absolute;
  top: -30px;
  left: 0;
} */
.booking-tabs-menu ul.p-tabmenu-nav{
  display: flex;
  flex-wrap: nowrap;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  overflow: scroll;
  overflow-y: hidden;
}
.booking-tabs-menu .p-tabmenu{
  overflow: scroll;
  overflow-y: hidden;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}
::ng-deep span.topbar-item-name {
  display: none;
}
ul.topbar-items li:nth-child(1) {
  display: none;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  right: 36px !important;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  margin-right: 16px;
}
.ui-panelmenu .ui-panelmenu-icon {
   margin-right: 14px;
}
::ng-deep .add_bth {
  width: 150px !important;
}
.cio-grid-size {
  width: 100% !important;
}
.add_bth_a {
  text-align: right;
  position: relative;
  right: 0;
  min-width: 100%;
  width: 100%;
  display: block;
}
.mt-20{
  margin-top: 20px;
}
.form-layout-box2 {
   margin-right: 20px;
}
.setting_h {
  margin-left: 15%;
}
.setting_h i.pi.pi-cog {
  margin: 8px 0px 16px 0px;
}
.paginator_h {
   width: 260px;
}
.add_bth_a {
  text-align: right !important;
  position: relative !important;
  right: 0 !important;
  min-width: 100% !important;
  width: 100% !important;
  display: block;
}
}

@media screen and (max-width: 480px)
{
.table-responsive {
  width: 100%;
  margin: 0 auto;
  overflow-y: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: none;
}
.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}
body .table1 .ui-table .ui-table-thead > tr > th:nth-child(1) {
  width: 50px;
}
body .table1 .ui-table .ui-table-thead > tr > th:nth-child(2) {
  width: 150px;
}
body .table1 .ui-table .ui-table-thead > tr > th:nth-child(3) {
  width: 150px;
}
body .table1 .ui-table .ui-table-thead > tr > th:nth-child(4) {
  width: 150px;
}
body .table1 .ui-table .ui-table-thead > tr > th:nth-child(5) {
  width: 150px;
}
body .table1 .ui-table .ui-table-thead > tr > th:nth-child(6) {
  width: 100px;
}
.add_bth {
  width: 100% !important;
 }
 span.topbar-item-name {
  display: none;
}
ul.topbar-items li:nth-child(1) {
  display: none;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  right: 36px !important;
}
.role-mapping-table {
  width: 100%;
}
body .role-mapping-table.table1 .ui-table .ui-table-thead > tr > th:nth-child(1) {
  width: 150px;
}
body .role-mapping-table.table1 .ui-table .ui-table-thead > tr > th:nth-child(2) {
  width: 70px;
}

::ng-deep .ui-table-wrapper.ng-star-inserted {
  margin: 0px !important;
}
::ng-deep .company_cont_per {
  font-size: 16px;
  line-height: 24px;
}
::ng-deep li.Settings_h {
  position: absolute;
  width: auto;
  right: 0 !important;
  top: 0;
}
.add_bth_a {
  text-align: right;
  position: relative;
  right: 0;
  min-width: 100%;
  width: 100%;
  display: block;
}
.cio-grid-size {
  width: 100% !important;
}
.ui-tabview .ui-tabview-nav li {
  list-style: none;
  float: none;
  position: relative;
  margin: 1px;
  padding: 0;
  white-space: nowrap;
}
.ui-tabview .ui-tabview-nav li a {
  font-size: 24px;
  line-height: 30px;
  font-family: Aileron-SemiBold;
}
span.ui-tabview-title.ng-star-inserted {
  font-size: 24px;
  line-height: 30px;
  font-family: Aileron-SemiBold;
}
.save_bth {
  padding: 3px 18px !important;
}
.cancel_bth {
  padding: 3px 18px !important;
}
.mt-20{
  margin-top: 20px;
}

}

/* ----------------------------------- */

body .ui-dropdown .ui-dropdown-trigger {
  background-color: #b98e0c !important;
  width: 40px;
  color: #ffffff;
  height: 40px;
  position: absolute;
  right: -1px;
}
.cio-grid-size {
  width: 60%;
}
body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  width: 40px;
  height: 40px;
  margin-left: -7px;
}
body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #ffffff !important;
  text-align: right;
  display: block;
  width: 97%;
  margin-top: -14px;
}
.w1 {
  width: 65px !important;
  min-width: 20px !important;
  max-width: 50px !important;
}

.w4 {
  width: 75px !important;
  min-width: 30px !important;
  max-width: 75px !important;
}

.w3{
  width: 110px !important;
  min-width: 20px !important;
  max-width: 50px !important;
}
body .ui-dialog .ui-dialog-titlebar {
  border: 1px solid #bd910e !important;
  color: #333333 !important;
  background-color: #896707 !important;
  background-image: linear-gradient(to right, #a57e0f, #c6980e) !important;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus {
  box-shadow: none !important;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
   color: #fff !important;
}

body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
  width: auto;
  color: #fff !important;
}

body .ui-table .ui-sortable-column:focus {
  box-shadow: none !important;
}

body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  box-shadow: none !important;
}

/*--- top menu ---*/

.topbar .topbar-right {
  padding: 15px !important;
  height: 60px;
  background-color: #896707;
  background-image: -webkit-gradient (linear, left top, right top, from(#896707), to(#c6980e)) !important;
  background-image: -webkit-linear-gradient(left, #896707, #c6980e) !important;
  background-image: -moz-linear-gradient(left, #896707, #c6980e) !important;
  background-image: -ms-linear-gradient(left, #896707, #c6980e) ;
  background-image: -o-linear-gradient(left, #896707, #c6980e) !important;
  background-image: linear-gradient(left, #896707, #c6980e) !important;
  background-image: linear-gradient(to right, #a57e0f, #c6980e) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#146fd7', endColorstr='#146fd7', gradientType='1');
}
.topbar .topbar-left {
  padding: 3px;
  height: 60px;
  width: 235px;
  float: left;
  text-align: center;
  background-color: #896707;
  background-image: -webkit-gradient (linear, left top, right top, from(#896707), to(#c6980e));
  background-image: -webkit-linear-gradient(left, #896707, #c6980e) !important;
  background-image: linear-gradient(to left, #c6980e, #896707) !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25) !important;
  color:#fff;
}

.layout-menu-container.layout-menu-dark {
  border-right: 1px solid #d7c853 !important;
  background-color:#d7c853 !important;
  background-image: -webkit-gradient(linear, left top, right top, from(#363a41), to(#363a41));
  background-image: -webkit-linear-gradient(left, #363a41, #363a41);
  background-image: -moz-linear-gradient(left, #363a41, #363a41);
  background-image: -ms-linear-gradient(left, #363a41, #363a41);
  background-image: -o-linear-gradient(left, #363a41, #363a41);
  background-image: linear-gradient(left, #363a41, #363a41) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#363a41', endColorstr='#363a41', gradientType='1');
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover {
  outline: 0 none !important;
  border: 1px solid #767676 !important;
  background-color: #767676 !important;
  color: #fffefe !important;
  border-radius: 0 !important;
}
.ui-panelmenu .ui-submenu-list {
  background: #767676 !important;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover {
  outline: 0 none;
  border: 1px solid #000000 !important;
  background-color: #000000 !important;
  color: #d4c137 !important;
  border-radius:0 !important;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a .ui-panelmenu-icon {
  color: #d4c137 !important;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover .ui-panelmenu-icon {
  color: #ffbe00 !important;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover .ui-panelmenu-icon {
  color: #ffffff !important;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 10px 10px !important;
  border: 1px solid #ffffff !important;
  font-weight: 700 !important;
  color: #ffffff !important;
  background-color: #c6a442 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-align: center !important;
}

body .ui-table .ui-table-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #c6a4429e;
  transition: box-shadow 0.2s;
  padding: 0px 8px !important;
  font-size: 13px !important;
  font-family: Aileron-SemiBold !important;
}
body .ui-paginator {
  background-color: #d7c853 !important;
  border: 1px solid #b78d0f !important;
}
body .ui-paginator .ui-paginator-first, body .ui-paginator .ui-paginator-prev, body .ui-paginator .ui-paginator-next, body .ui-paginator .ui-paginator-last {
  color: #000000 !important;
  background: #d7c853 !important;
}
/*-- cio-grid ---*/
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #ab830f !important;
  color: #ffffff !important;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #f8f7f4 !important;
  font-weight: 700;
  color: #fcfcfc !important;
  background-color: #b98e0c !important;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #bb8f0f !important;
  background-color: #b1870e !important;
  color: #ffffff !important;
}

body .ui-button:enabled:focus {
  box-shadow: none !important;
}
body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  width: 40px !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 40px !important;
  position: absolute;
  right: 0 !important;
}
.ui-calendar {
  position: relative !important;
  display: inline-block !important;
  width: 100%;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  color: #ffffff;
  background-color: #b98e0c;
}

body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #f9f9f9 !important;
  border: 1px solid #c6a4429e !important;
}
.align_h{
  padding: 6px !important;
}
body .ui-inputtext {
  width: 100% !important;
}

.box1_w{
  width: 320px !important;
}
.error-high-text{
  width: 175px;
  white-space: normal;
  height: auto !important;
  line-height: normal !important;
}
.error {
  color:#f44336  !important;
  font-size: 11px  !important;
  display: block  !important;
  margin: 0 !important;
  line-height: normal;
}
table tr > td .error{
  position: absolute;
  bottom: 0;
}

body .ui-inputtext {
  height: 48px;
}

body .ui-button {
  background-color: #b68b0e !important;
  border: 1px solid #b48a0e !important;
}



/****03 Fab 2021****/
.header-widget{
  background-color: #02263b;
  position: fixed;
  width: 100%;
  z-index: 2;
  top: 0;
  height: 32px;
}

.toggler-menu{
  position: absolute;
  right: 10px;
  top: 13px;
  color: rgba(255, 255, 255, 0.7);
}
.toggler-menu:hover,
.toggler-menu:focus{
  color: rgba(255, 255, 255, 0.7);
}
.brand-logo{
  max-width: 120px;
  margin: 4px 0 4px 15px;
}
.labsoul-logo > img{width: 37px;}
.login-footer ul > li{padding-right: 20px !important; margin-right: 20px !important; position: relative;}
.login-footer ul > li:after{content: ''; display: block; width: 1px;
  height: 37px; border: solid 1px #dbdbdb; position: absolute; top:0; right: 0;}
.login-footer ul > li:last-child{margin-right: 0;}
.login-footer ul > li:last-child:after{content: ''; border: none;}
.login-footer ul li:only-child:after{content: ''; border: none;}

.p-button.p-button-text{
  color: #fff;
  background-color: #004169 !important;
  font-size: 13px;
  padding: 5px 15px;
  border-radius: 4px;
  font-weight: 600;
}
.p-button.p-button-text:hover{
  background-color: #00375a !important;
  color: #fff !important;
}
.button-border{
  background-color: transparent;
  color: #004169;
  font-size: 14px;
  border: 1px solid #004169;
  padding: 5px 15px;
}
.button-border:hover{
  background-color: #e9f6fc !important;
  color: #004169 !important;
  border: 1px solid #004169 !important;
}
.toggler-btn{display: none;}
.vertical-menu{
  background-color: #02263b;
  position: fixed;
  left: 0;
  height: 100%;
  /* z-index: 3; */
  width: 72px;
  top:32px;
}
.vertical-menu ul{
  padding: 0;
  margin: 0 !important;
  list-style-type: none;
  font-size: 11px;
  text-align: center;
  padding-bottom: 90px;
}

.vertical-menu ul > li:last-child a{
  height: auto;
  width: 72px;
  height: 50px;
  display: block;
  background-color: #02263b;
}
.vertical-menu ul > li:last-child a::before{
  content: '';
  display: block;
  background-color: #415663;
  height: 1px;
  width: 30px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  left: 50%;
}
.vertical-menu ul > li a{
  height: 70px;
  display: block;
}
/* .vertical-menu ul > li a.active{
  background-color: #023655;
} */
.vertical-menu ul > li a:hover{
  background-color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}
.vertical-menu ul > li > a{
  color: #fff !important;
  padding: 10px 4px;
  cursor: pointer;
}
.vertical-menu ul > li a > span{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 0;
}
.left-sidebar{
  width: 144px;
  left: 72px;
  background-color: #023655;
  height: 100vh;
  position: fixed;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  padding: 10px 0 60px;
  /* z-index: 9; */
  top:32px;
}
.left-sidebar .p-menu{
  background-color: #023655;
  border: none;
  width: 100%;
}
.left-sidebar .p-menu .p-menuitem{
  margin:0 4px;
  margin-right: 10px;
}
.left-sidebar .p-menu .p-menuitem-link{
  padding: 5px 7px;
  display: block;
  border-radius: 4px;
}
.left-sidebar .p-menu .p-menuitem-link .p-menuitem-text{
  color: #fff;
  font-size: 12px;
  display: block;
  line-height: 20px;
}
.left-sidebar .p-menu .p-menuitem-link:not(.p-disabled):hover{
  background-color: rgba(255, 255, 255, 0.2);
  color:#fff;
}
.left-sidebar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
  color:#fff;
}

.left-sidebar .p-menu .p-menuitem-link:focus{
  box-shadow: none;
}

.left-sidebar .p-menu ul{
  padding-bottom: 50px;
  padding-top: 10px;
  margin-left: 5px;
}

.left-sidebar .p-menuitem-link-active,
.left-sidebar .p-menuitem-link-active:hover{
  background-color: #ffcb08 !important;
}

.left-sidebar .p-menuitem-link-active > span,
.left-sidebar .p-menuitem-link-active > span:hover{
  color: #014268 !important;
  font-weight: 600;
  display: inline-block;
}

.left-sidebar > ul{
  margin: 0;
  margin-top: 10px;
  padding-left: 8px;
  width: 175px;
  right: 8px;
  position: absolute;
  padding-bottom: 50px;
}
.left-sidebar > ul .p-component > .p-panelmenu-panel:nth-child(6) .p-panelmenu-icon,
.left-sidebar > ul .p-component > .p-panelmenu-panel:nth-child(7) .p-panelmenu-icon{visibility: hidden;}

.main-wrapper{
  display: flex;
  padding-top: 32px;
}
.main-wrapper >.inbox-outlet{
  width: 100%;
  background-color: #ffffff;
  margin-left: 216px;
  height: 100%;
  min-height: 100vh; 
  overflow: hidden;
}
.full-width-screen{margin-left: 0 !important;}
.top-header{
  padding: 24px 24px 0 24px;
  box-sizing: border-box;
}
.top-header p{
  color:#616161;
  font-size: 14px;
  margin-bottom: 15px;
}
.top-header h1{
  color: #000000;
  font-weight: 800 !important;
  font-size: 18px !important;
  margin-bottom: 25px;
  font-family: 'Open Sans' !important;
  line-height: normal;
}
/* .top-header .col-md-2 button{
  text-align: right;
} */
.custom-breadcrumb{
  list-style-type: none;
  padding: 0;
  font-size: 13px;
  display: none;
}
.custom-breadcrumb > li{
  position: relative;
  display: inline-block;
}
.custom-breadcrumb > li:after{
  content: "\e901";
  font-family: 'primeicons';
  font-size: 10px;
  margin: 0 5px;
}
.custom-breadcrumb > li:last-child::after{
  content: none;
}
.custom-breadcrumb > li:only-child::after{
  content: none;
}
.custom-breadcrumb > li > a{
  color:#004169 !important;
}
.custom-breadcrumb > li > a:hover{
  text-decoration: none;
}
.custom-breadcrumb > li:first-child{
  color: #000000;
}

.current-page{
  color: rgba(0, 0, 0, 0.5);
}
.title-area{
  text-align: center;
  color: #b2b2b2;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title-area h2{
  font-size: 2.3rem;
  color: rgba(0, 0, 0, 0.2);
}
.mat-h2, .mat-title, .mat-typography h2{
  font: inherit;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 30px;
}

.p-panelmenu .p-panelmenu-header > a{
  background: none;
  color: #ffffff;
  font-weight: normal;
  font-size: 12px;
  border: none;
  text-transform: uppercase;
  padding: 8px 0;
  font-family: 'OpenSansRegular';
}
.p-panelmenu .p-panelmenu-content{
  background: none;
  border: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text{
  color:#fff;
  font-size: 13px;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  box-shadow: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover{
  background: #344b58;
  border-radius: 5px;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover{
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.pi-chevron-right:before{
  position: absolute;
  right: 5px;
  top: 15px;
  font-size: 10px;
}

.pi-chevron-down:before {
  content: "\e902";
  position: absolute;
  right: 5px;
  top: 15px;
  font-size: 10px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link{
  padding:6px 10px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus{
  box-shadow: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text{
  color: #fff;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  background: #ffcb08;
  border-color: #ffcb08;
  color: #021e2f;
}
/*.p-panelmenu > .p-panelmenu-panel:last-child{
  position: absolute;
    bottom: 0;
    width: 200px;
    left: 0;
    background-color: #021e2f;
}
.p-panelmenu > .p-panelmenu-panel:last-child a{
  text-transform: capitalize;
  padding: 15px 5px;
}
.p-panelmenu > .p-panelmenu-panel:last-child a:hover{
  background: transparent !important;
  color: #fff !important;
}
.p-panelmenu > .p-panelmenu-panel:last-child .pi-chevron-right:before,
.p-panelmenu > .p-panelmenu-panel:last-child .pi-chevron-down:before{
  top: 20px;
  right: 10px;
}
.p-panelmenu > .p-panelmenu-panel:last-child:before{
  content: '';
    width: 170px;
    height: 1px;
    background-color: #3e5360;
    display: block;
    left: 15px;
    position: absolute;
}*/
.p-panelmenu .p-menuitem-text{
  line-height: 20px !important;
  display: block;
}
/*.user-info-widget::before{
  content: '';
  background-color: #415663;
  display: block;
  height: 1px;
  width: 83%;
  margin: 0 auto;
}*/
.user-info-widget{
  background-color: #021e2f;
  position: fixed;
  left: 0;
  width: 200px;
  bottom: 0;
  color: #88969d;
  font-size: 14px;
  font-weight: normal;
  z-index: 2;
}
.user-info-widget > ul{
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 40px;
  padding-left: 15px;
  margin-top: 10px;
}

.user-info-widget > ul i.fa-angle-down{
  position: absolute;
  right: 17px;
  top: 15px;
}
.user-info-widget > ul span{
  padding-left: 10px;
}


.middle-sec{
  box-sizing: border-box;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 100px;
}
.middle-sec .container{min-width: 100%;}

.popup-screen{
  width: 75% !important;
  padding:0;
}
.popup-screen-history{
  right: 0;
  width: calc(100% - 58%)!important;
  display: block;
  padding:0;
}
.popup-screen .position-end{
  left: 0;
}
.cdk-overlay-container {
  position: fixed;
  z-index: 999999 !important;
}
.mat-select-panel{
  z-index: 2000000 !important;
}
.p-dialog-mask{z-index: 2 !important;}

.modal-header{
  border-bottom: 1px solid #f0f0f0;
}
.modal-header h2{
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: 'OpenSansRegular' !important;
}
.modal-top-header{
  display: block !important;
  padding: 10px 15px !important;
}
.modal-top-header a{
  margin-bottom: 10px;
  display: inline-block;
  color: #004169;
  font-weight: 600;
  font-size: 14px;
}
.modal-top-header a i{
  font-size: 16px;
  margin-top: 3px;
  margin-right: 4px;
  float: left;
  margin-left: -3px;
}
.modal-top-header a:hover{
  text-decoration: none;
}

/*
.p-disabled, .p-component:disabled {
  opacity: inherit;
  background-color: #d4d4d4;
  color: #707070;
  border: 1px solid #d4d4d4;
}
.p-disabled:hover, .p-component:disabled:hover {
  opacity: inherit;
  background-color: #d4d4d4;
  color: #707070;
  border: 1px solid #d4d4d4;
}*/
.border-btn{
  border: 1px solid #707070 !important;
  padding: 6px 15px;
  color: #9f9f9f !important;
  font-family: 'OpenSansRegular' !important;
  font-size: 14px;
}
.save-btn{
  background-color: #c6c6c6 !important;
  padding: 7px 15px;
  color: #707070 !important;
  font-family: 'OpenSansRegular' !important;
  border: none !important;
  font-size: 13px;
  box-shadow: none !important;
  padding: 5px 15px;

}
.save-dark-btn{
  color:#ffffff !important;
  background-color: #004169 !important;
  padding: 7px 15px;
  font-size: 13px;
  border-radius: 5px;
}
.save-exit{
  color: #fff;
  background-color: #17a591;
  font-size: 13px;
  /*border-radius: 20px;*/
  border: 1px solid #17a591;
  font-weight: 600;
}
.save-exit:hover{
  color: #fff;
  background-color: #17a591 !important;
  font-size: 13px;
  /*border-radius: 20px;*/
  border-color: #17a591 !important;
}
.save-button{
  color: #fff;
  background-color: #004169;
  font-size: 13px;
  /*border-radius: 20px;*/
  border: 1px solid #004169;
  font-weight: 600;
  padding: 5px 15px;
}
.save-button:hover{
  background-color: #004169 !important;
  border: 1px solid #004169 !important;
  color: #ffffff !important;
}
.save-button:focus,
.p-button:focus{
  box-shadow: none  !important;
}
.reset-button{
  color: #949494;
  background-color: transparent;
  border: none;
  font-size: 13px;
  /* border-radius: 20px; */
  font-weight: 600;
  border: 1px solid #ffffff !important;
  padding: 5px 15px;
}
.reset-button:hover{
  background-color: #cdcdcd !important;
  border: 1px solid #cdcdcd !important;
  color: #444444 !important;
}
.cancel-button{
  color: #000000;
  background-color: #eaeaea;
  border: 1px solid #eaeaea;
  font-size: 13px;
  /*border-radius: 20px;*/
  font-weight: 600;
}
.cancel-button:hover{
  background-color: #eaeaea !important;
  border: 1px solid #eaeaea !important;
  color: #444444 !important;
}
.close-button{
  background-color: #ffffff !important;
  border: 1px solid #d4d4d4 !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  font-size: 12px;
  font-family: 'OpenSansRegular';
  padding: 5px 15px;
  height: 33px;
}
.close-button:hover{
  background-color: #cdcdcd !important;
  color: #707070 !important;
}

.p-button:disabled {
  cursor: default;
  background-color: #d4d4d4;
  opacity: 1;
  border-color: #d4d4d4;
  color:#707070;
}
.p-button:disabled,
.p-button:disabled:hover{
  background-color: #d4d4d4 !important;
  border-color: #d4d4d4 !important;
  color: #707070 !important;
}

.font-20{
  font-size: 20px;
}
.color-blue{
  color: #004169;
}
.position-end{
  position: fixed;
  bottom: 0;
  left: 216px;
  z-index: 15;
  -webkit-transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
	transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  width: calc(100% - 216px)!important;
}
.position-end .save-button:hover{
  background-color: #00375a;
}


.mat-radio-label{
  margin-right: 15px;
  font-weight: 500;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #004169;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #004169;
}


.add-rates{
  color:#004169 !important;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'OpenSansRegular';
}
.add-rates > .fa-trash-o{
  float: right;
}
.add-rates:hover{
  text-decoration: none;
}
.add-rates i{
  font-size: 20px;
}
.add-discounts-table .table tr td{width:auto; border: none;}
.add-discounts-table .table .thead-light th{
  background-color: #e0e0e0;
  border:none;
  padding: 8px 15px;
  font-size: 14px;
}
.add-discounts-table table tr > td{
  vertical-align: middle;
  border-top: none;
}
.add-discounts-table table th{
  border-top: none;
}
.add-discounts-table table tbody + tbody{
  border-top:none;
}

.mat-input-element{
  line-height: 16px !important;
  font-size: 14px !important;
}
.mat-form-field-label{
  color:#343434 !important;
  line-height: 16px !important;
  height: -webkit-fill-available;
}
.mat-select-placeholder{
  color:#343434 !important;
}


.category-tbl tr th{
  padding: 5px;
  background-color: #cfcece;
  font-size: 14px;
}
.category-tbl tr td{
  padding: 5px;
  background-color: #fff;
  font-size: 14px;
}
.category-tbl td, .category-tbl th {
  border: 1px solid #d4d4d4 !important;
}
/* .common-table .p-datatable .p-datatable-thead>tr>th {
  position: sticky;
  top: 5rem;
} */

.table tbody+tbody {
  border-top: 1px solid #dee2e6 !important;
}
.common-table .isNoRecordFound{
  padding: 15px 0;
}
.common-table{
  background-color: #fff !important;
  border: none !important;
  /* overflow-x: auto; */
}
.common-table .p-datatable table{
  table-layout: auto;
}

.common-table .p-datatable .p-datatable-tbody > tr > td .fa-ellipsis-v::before{
  content: '';
  background: url(src/assets/images/action-icon.svg);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  width: 21px;
  height: 21px;
}
.common-table .p-paginator{
  border: none !important;
  background-color: #fff;
  justify-content: flex-start!important;
}
.common-table .p-datatable .p-datatable-thead > tr > th{
  border: none !important;
  background-color: #cfcece;
}

.common-table .p-datatable .p-datatable-thead > tr > th{
  font-weight: 600;
  font-size: 14px;
  padding: 10px 10px;
}
.common-table .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  background: none;
  border-bottom: 2px solid #f1c21b;
  color: #161616;
}
.common-table .p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus{
  box-shadow: none !important;
}
.common-table .p-paginator{
  margin-top: 15px;
}
.common-table .p-paginator .p-dropdown,
.common-table .p-paginator .p-dropdown:focus{
  border: none;
  box-shadow: none;
}
.common-table .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #02263b;
}
.common-table .p-dropdown .p-dropdown-panel{
  min-width: 60px;
}
.common-table .p-dropdown-item{
  font-weight: 600;
  font-size: 14px;
}
.common-table .p-datatable .p-sortable-column.p-highlight:hover {
  background: #cfcece;
  color: #272727;
  outline: none;
}
.common-table .p-datatable .p-sortable-column:focus{
  box-shadow:none;
  color: #272727;
}
.common-table .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon{
  color: #272727;
}
.common-table .p-datatable .p-sortable-column.p-highlight{
  color: #272727;
}
.common-table .p-datatable .p-datatable-tbody > tr{
  border-bottom: 1px solid #eeeeee;
  font-family: 'OpenSansRegular';
}
.common-table .p-datatable .p-datatable-tbody > tr.active,
.common-table .p-datatable .p-datatable-tbody > tr.active:hover{
  background-color: #dce6eb !important;
  border-top: solid 1px rgba(182, 182, 182, 0.47);
  border-bottom: solid 1px rgba(182, 182, 182, 0.47);}
.common-table .p-datatable .p-datatable-tbody > tr:hover{
  background-color: #f5f4f4;
}
.common-table .p-datatable .p-datatable-tbody > tr > td{
  border:none;
  font-size: 14px;
}
/* .common-table .p-datatable .p-datatable-tbody > tr > td:nth-child(1){
  text-align: center;
} */
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #f5f4f4;
  color: #000000;
}
.common-table .p-datatable-tbody{
  border: none;
}

.header-fixed thead{position: sticky;top: 0; z-index: 10;}

.selected-items{
  background-color: #004169;
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.selected-items ul:nth-child(1){
  margin: 0;
  padding: 0;
  list-style: none;
  width: 30%;
  float: left;
  box-sizing: border-box;
  font-size: 12px;
  color: #fff;
  text-align: left;
  padding-left: 15px;
}
.selected-items ul:nth-child(2){
  margin: 0;
  padding: 0;
  list-style: none;
  width: 70%;
  float: left;
  box-sizing: border-box;
  font-size: 12px;
  color: #fff;
  text-align: right;
  padding-right: 15px;
}
.selected-items ul li{
  display: inline-block;
  padding: 0 5px;
}
.user-active {
  font-size: 10px;
  color:#65b224;
  margin-right: 5px;
}
.user-in-active {
  font-size: 10px;
  color:#df5353;
  margin-right: 5px;
}
.button-border{
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #004169;
  border: 1px solid #004169;
  background-color: transparent;
}
.mat-select-arrow{
  background: url(src/assets/images/down-arrow.svg);
  width: 16px !important;
  height: 16px !important;
  background-size: cover;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}
.mat-date-range-input-separator{margin: 0 2px !important;}
.filter-widget .mat-input-element{margin-top: 0 !important;}
.filter-widget .mt-1, .filter-widget .mt-2{margin-top: 0 !important;}
.filter-widget{
  margin-bottom:10px;
}
.filter-widget .date-mt{margin-top: 2px;}
/* .filter-widget .mat-form-field-type-mat-select .mat-form-field-infix{margin-top: -3px;} */
.filter-widget .mat-form-field-hide-placeholder .mat-select-placeholder{
  height: auto !important;
}
/* .filter-widget .mat-form-field-type-mat-select{
  margin-top: 6px ;
} */
/* .filter-widget .form-row > .col-auto:nth-child(1) .ng-star-inserted span{display: none;} */
.filter-widget .save-button{
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #004169;
  border: 1px solid #004169;
  background-color: transparent;
  padding: 5px 15px;
}
.filter-widget .save-button:hover{
  background-color: #e9f6fc !important;
  color: #004169 !important;
}

.search-filter{
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #004169;
  border: 1px solid #004169;
  background-color: transparent;
  padding: 5px 15px;
  font-size: 13px;
  background: url(/assets/images/filter-icon.svg) no-repeat;
  width: 96px;
  text-align: left;
  background-position: 80% 50%;
}
.search-filter:hover{
  color: #004169 !important;
  background: url(/assets/images/filter-icon.svg) no-repeat #e9f6fc !important;
  background-position: 80% 50% !important;
}

.auto-filter .mat-form-field-infix{
  width: 135px;
}

.edit-price-list{
  font-weight: 600;
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #004169;
  border: 1px solid #004169;
  background-color: transparent;
  padding: 5px 15px;
}
.edit-price-list:hover {
  background-color: #e9f6fc !important;
  color: #004169 !important;
}
.apply-btn > button{
  background: transparent;
  border: 1px solid #004169;
  color: #004169;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 25px;
  border-radius: 20px;
}
.apply-btn > button:hover{
  background-color: #ffcb08 !important;
    color: #000 !important;
    border: 1px solid #ffcb08 !important;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #004169 !important;
  font-weight: normal;
  font-size: 13px;
  padding: 0 10px;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline{
  height: 1px !important;
  background-color: rgba(0, 0, 0, 0.7);
}
.mat-form-field-appearance-legacy .mat-form-field-ripple{
  height: 1px;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before{
  height: 1px !important;
  background-color: rgba(182, 182, 182, 0.7);
}


/* .scrollbar-pink::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px; }


  .scrollbar-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px; }

  .scrollbar-indigo::-webkit-scrollbar {
  width: 0;
}

  .scrollbar-indigo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.25); }

  .scrollbar-black::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px; }

  .scrollbar-black::-webkit-scrollbar {
  width: 12px;
  }

  .scrollbar-black::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #000; }

  .scrollbar-lady-lips::-webkit-scrollbar-track {
  border-radius: 10px; }

  .scrollbar-lady-lips::-webkit-scrollbar {
  width: 12px;}

  .scrollbar-lady-lips::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ff9a9e), color-stop(99%, #fecfef),
  to(#fecfef));
  background-image: -webkit-linear-gradient(bottom, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
  background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%); }

  .scrollbar-near-moon::-webkit-scrollbar-track {

  border-radius: 10px; } */



  .scrollbar-near-moon::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
  background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%); }


  /* .bordered-pink::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none; }

  .bordered-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: none;}

  .bordered-indigo::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none; }

  .bordered-black::-webkit-scrollbar-track {
  -webkit-box-shadow: none; }

  .bordered-black::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none; } */

  .square::-webkit-scrollbar-track {
  border-radius: 0 !important; }

  .square::-webkit-scrollbar-thumb {
  border-radius: 0 !important; }

  .thin::-webkit-scrollbar {
  width: 6px; }

.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: .5375em 0 !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 10px 10px;
  color: #000000;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0,0,0,.55) !important;
}
.mat-form-field.mat-focused .mat-form-field-label{
  color: #000 !important;
}

  table .mat-menu-trigger{
    background-color: transparent !important;
    border: none !important;
    line-height: normal;
  }
  .mat-icon-button{
    height: auto !important;
    border-radius: initial !important;
  }

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: #004169;
}
.mat-form-field{
  font-family: 'OpenSansRegular' !important;
  width: 100%;
}
.mat-h4, .mat-subheading-1, .mat-typography h4{
  font-family: 'OpenSansRegular';
}
.mat-form-field-appearance-fill .mat-form-field-flex{
  background-color: transparent;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #004169;
}
.mat-tab-label-active .mat-tab-label-content{
  color: #004169;
  font-weight: 600;
  font-family: 'OpenSansRegular';
}
.mat-tab-label{
  opacity: inherit !important;
  height: 35px !important;
  min-width: auto !important;
  padding: 0 30px !important;
  font-weight: normal !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(182, 182, 182, 0.7);
}
.bg-light1 {
  background-color: #eeeff1!important;
}
.add-more{
  color: #004169;
  text-decoration: none;
  font-weight: 600;
  font-family: 'OpenSansRegular';
}
.add-more:hover{
  text-decoration: none;
}

.custom-drop-box .add-more-file,
.custom-drop-rreview .trim-text + .ng-star-inserted{
  pointer-events: visible;
}

.custom-drop-box{
  min-height: 96px !important;
  height: auto !important;
  border: 1px dashed #cbcbcb !important;
  overflow-x: inherit !important;
  flex-wrap: wrap !important;
  background: #f7f7f7 !important;
  pointer-events: none;
  margin-left: 0 !important;
}
.custom-drop-box .a-tag-color{
  pointer-events: visible;
}
.custom-drop-box-show{
  background: transparent !important;
  /* pointer-events: none; */
}
.custom-drop-rreview .trim-text{text-align: center;}
.custom-drop-rreview{
  margin: 5px !important;
  min-width: 120px !important;
  background-color: #f7f7f7;
  background-image: none !important;
  padding: 0 !important;
  border-radius: 4px !important;
}
.custom-drop-rreview p{
  margin: 0;
  font-size: 14px;
  pointer-events: visible;
}
.custom-drop-rreview p > span{
  display: block;
  margin: 5px 0;
}
.custom-drop-rreview p > strong{
  color: #004169;
  font-size: 13px;
  font-weight: 600;
}
.browse-file{
  color: #004169;
  display: block;
  font-size: 12px;
  font-weight: 600;
}


.mat-menu-content:not(:empty){
  padding: 0 !important;
}
.mat-menu-item{
  line-height: 35px !important;
  height: 35px !important;
}
.mat-menu-panel{
  min-width: 180px !important;
  max-width: 230px !important;
  margin-top: 8px;
  min-height: auto !important;
  background-color: #fff !important;
}
.lab-data{
  color: #000 !important;
  font-size: 12px !important;
  font-weight: 600
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color:rgba(0, 0, 0, 0.85);
}
.custom-time-picker{
  position: relative;
}
.custom-time-picker svg{
  position: absolute;
  right: 0;
  top: 18px;
  width: 17px;
  height: 17px;
}
.custom-time-picker > input{
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-top: 13px;
  height: 42px;
  color: #000;
}
.custom-time-picker > input:focus{
  border: none;
  border-bottom: 2px solid #343434;
  outline: none;
  transition-duration: 0.2s;
}
.mat-calendar-previous-button::after,
.mat-calendar-next-button::after{
  top:-4px !important;
}
/* .mat-form-field-type-mat-select .mat-form-field-infix{
  margin-top: -2px;
} */
.filter-widget .cdk-text-field-autofill-monitored:not(:-webkit-autofill){
  color: #000000;
  /* height: 20px; */
}
/* .filter-widget .mat-form-field-hide-placeholder .mat-select-placeholder{
  height: 20px;
} */
.filter-widget .save-button, .filter-widget .reset-button, .filter-widget .p-button-text{margin-top: -5px;}
.filter-widget .custom-p-dropdown{width: 100%; float: left; margin-top: -5px;}
.filter-widget .mat-form-field-appearance-legacy .mat-form-field-underline{bottom: 1.21em !important;}

.modal-header,
.modal-body{
  padding: 15px 24px !important;
}
.p-sidebar .p-sidebar-close{
  color: #000;
  padding-right: 24px;
  width: 55px;
  /* padding-top: 17px; */
  height: 55px;
}
.p-sidebar .p-sidebar-close:focus{
  box-shadow: none;
}
.p-sidebar .p-sidebar-close:hover{
  color: #000 !important;
}
.add-bulk{
  font-stretch: normal;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #004169;
  border: 1px solid #004169;
  background-color: transparent;
  font-size: 13px;
  font-family: OpenSansRegular;
  padding: 5px 15px;
  border-radius: 4px;
}
.add-bulk:hover{
  background-color: #e9f6fc !important;
  color: #004169 !important;
  border: 1px solid #004169 !important;
}
.add-bulk i{
  font-size: 18px;
}
.font-6{
  font-size: 6px !important;
}
.font-8{
  font-size: 8px !important;
}
.font-10{
  font-size: 10px !important;
}
.font-11{
  font-size: 11px !important;
}
.font-12{
  font-size: 12px !important;
}
.font-13{
  font-size: 13px !important;
}
.font-14{
  font-size: 14px !important;
}
.font-15{
  font-size: 15px !important;
}
.font-weight-normal{
  font-weight: normal;
}
.font-weight-600{
  font-weight: 600 !important;
  font-family: 'OpenSansRegular';
}
.font-weight-500{
  font-weight: 600;
  font-family: 'OpenSansRegular';
}



/*File Upload Input*/
.custom-file-uploder{
  background-color: #f7f7f7;
  border: dashed 1px #cbcbcb;
  height: 128px;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}
.custom-file-uploder .filename{
  height: 20px;
}
.custom-file-uploder .mat-form-field-underline{
  display: none;
}
.file-title{
  display: block;
  text-align: center;
  font-size: 12px;
  color: #979797;
  line-height: 18px;
}
.file-browse{
  display: block;
  text-align: center;
  font-size: 12px;
  color: #004169;
  font-weight: 600;
}
.custom-file-uploder .fileIcon{
  display: none;
}
.custom-file-uploder .mat-form-field-infix{
  border-top:none;
  padding: 0;
}
.custom-file-uploder .filename,
.custom-file-uploder u.pointer{
  font-weight: 600;
  font-size: 13px;
  margin-top: 5px;
  text-decoration: none;
}
.custom-fileupload-label{
  line-height: normal;
  color:#000;
}
.custom-fileupload-label > span{
  font-size: 12px;
  color: #868686;
  display: block;
}

/***Custom Time Picker***/
.timepicker__header{
  background-color: #004169 !important;
}
.timepicker-button{
  color: #004169 !important;
}
.clock-face__number> span.active{
  background-color: #004169 !important;
}
.clock-face__clock-hand {
  background-color: #004169 !important;
}
.clock-face__clock-hand_minute:before{
  border: 4px solid #004169 !important;
}
.timepicker-dial__control:focus{
  outline: none !important;
}

/***Custom Date picker***/
.mat-datepicker-toggle-default-icon{
  background: url(assets/images/calendar-icon.svg) #ffffff;
  background-repeat: no-repeat;
  width: 16px !important;
  height: 16px;
}
.common-table .p-datatable .p-datatable-tbody > tr.active .mat-datepicker-toggle-default-icon{background-color: transparent;}
.mat-datepicker-toggle-default-icon > path{
  display: none;
}
.mat-calendar-body-selected {
  background-color: #004169 !important;
  color: #fff !important;
}


/*Tooltip*/
.mat-tooltip.tooltip{
    font-size: 10px !important;
}
.trim-text{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 70px;
  display: block;
}

.tooltip-trim{
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 12px;
  line-height: normal;
}
.a-tag-color{
  color: #004169 !important;
}
.a-tag-color:hover{
  text-decoration: none;
}
.defualt-link{
  color: inherit;
}
.defualt-link:hover{
  color: inherit;
  text-decoration: none;
}

/***Mat Accordion***/
.mat-expansion-panel-header.mat-expanded {
  height: 48px !important;
}
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]){
  background: transparent !important;
}
.mat-expansion-panel-header{
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.mat-expansion-panel-body{
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/**Dailog Box***/
.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #000;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus{box-shadow: none;}
.mat-error{
  height: 15px;
  font-size: 11px;
}
.mat-hint{
  height: 12px;
}

/***Custom Chips**/
.mat-chip.mat-standard-chip {
  background-color: #e4e7e9;
  color: rgba(0,0,0,.87);
  font-size: 13px;
}

.fa-download:before{
  content: '';
  background: url(assets/images/download-sgv-icon.svg) no-repeat;
  background-size: 90%;
  width: 20px;
  height: 20px;
  display: block;
}
.text-black {
  color: #000000!important;
}
.cursor-pointer{
  cursor: pointer;
}
.mat-option {
  line-height: normal !important;
  height: auto !important;
  padding: 10px 10px !important;
  font-size: 14px !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #004169 !important;
  background: #004169 !important;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s !important;
  color: #ffffff !important;
  font-size: 10px !important;
}
.p-checkbox .p-checkbox-box {
  width: 16px !important;
  height: 16px !important;
}

.delete-stop{
  width: auto;
  font-size: 20px;
  color: #023655;
  visibility: visible;
  line-height: 30px;
}
.delete-stop .mat-icon{
  width: 30px;
  height: 30px;
  font-size: 20px;
}

.delete-point{
  width: auto;
  font-size: 18px;
  color: #023655;
  visibility: visible;
  line-height: 30px;
}
.delete-point .mat-icon,
.delete-point.mat-icon-button{
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: normal;
}
.delete-point:hover{
  color: #023655
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #9a9a9a !important;
  font-size: 14px !important;
  padding-left: 0;
}
.modal-header{
  border-bottom: none !important;
}

.mat-listing {
  padding-left: 10px;
}
.mat-listing > li{
  font-size: 10px;
  color: #3f3f3f;
}

/****P Dialog***/
.custom-pdialog .p-dialog-header .p-dialog-title{margin-bottom:0;}
.custom-pdialog .p-dialog-content{font-family: 'OpenSansRegular'; font-size: 15px; padding-top: 0;}
.custom-pdialog .p-dialog .p-dialog-header .p-dialog-title{font-size: 16px;}
.custom-pdialog .p-dialog-footer button[type="button"]{
  background-color: #004169;
  border: none;
  font-size: 14px;
}
.custom-pdialog .p-dialog-footer button[type="button"]:hover{border: none !important;}
.custom-pdialog .p-dialog-footer button > .p-button-icon{display: none;}
.custom-pdialog .warning-msg{background-color: #ffefef; box-sizing: border-box; padding: 10px 45px;  margin: 20px 0 10px;}
.custom-pdialog .warning-text{color: #e02020; font-size: 14px; font-weight: 600; margin-bottom: 8px; margin-left: -30px;}
.custom-pdialog .warning-text >img{margin-right: 10px; float: left; margin-top: 4px;}



.p-dialog .p-dialog-header{
  border-bottom: none;
  background: #fff;
  padding-top: 20px;
}
.p-dialog .p-dialog-footer{
  border-top: none;
  padding-bottom: 30px;
}
.p-dialog-header > span{
  color: #000000;
  font-weight: 800;
  font-size: 18px;
  margin-bottom: 25px;
  font-family: 'OpenSansRegular';
}
.p-dialog .p-dialog-header > h1{
  color: #000000;
  font-weight: 800;
  font-size: 16px;
  font-family: 'Open Sans';
  line-height: normal;
  margin-bottom: 0;
}
.p-dialog .p-dialog-header > h1 > span{
  display: block;
  font-size: 13px;
  font-weight: 600;
  font-family: 'OpenSansRegular' !important;
  margin-top: 5px;
}
.p-dialog-content p{
  font-family: 'OpenSansRegular';
  font-size: 14px;
  line-height: 20px;
}
.p-dialog-content ul {
  font-family: 'OpenSansRegular';
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  list-style: none;
  padding-left: 0;
}
.p-dialog-content ul li::before{
  content: "\2022";
  color: #5c5c5c;
  font-weight: bold;
  display: inline-block;
  width: 15px;
  font-size: 16px;
}
.mat-list{
  list-style-type: none;
  padding: 10px;
  margin: 0;
}
.mat-list > li{
  font-size: 13px;
  font-family: 'OpenSansRegular';
}
.listed > li:before{
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #5c5c5c;
  margin-right: 5px;
}
.fontstyle-area .mat-button-toggle-appearance-standard .mat-button-toggle-label-content{line-height: 33px;}
.fontstyle-area .mat-button-toggle-group mat-button-toggle:nth-child(1){font-weight: 600;}
.fontstyle-area .mat-button-toggle-group mat-button-toggle:nth-child(2){font-style: italic;}
.fontstyle-area .mat-button-toggle-group mat-button-toggle:nth-child(3) .mat-button-toggle-label-content{text-decoration: underline;}
.p-fileupload-choose {
  width: auto;
  height: 30px;
  color: #004169;
  font-size: 12px;
  background-color: #fff;
  border: 1px solid #004169;
  display: inline-flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: 'OpenSansRegular';
  cursor: pointer;
}
.p-fileupload-choose:not(.p-disabled):hover{
  background: transparent;
  color: #004169;
}
.p-fileupload-choose .p-button-label{
  width: auto;
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-0{
  left: 0;
}
.right-0{right: 0;}
.z-index-1{z-index: 1 !important;}
.p-multiselect .p-multiselect-label{
  padding-left: 0;
  font-size: 14px !important;
  color: #21252f !important;
  font-family: 'OpenSansRegular'!important;
  font-weight: normal !important;
  padding-left: 0 !important;
}
.p-multiselect-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
  font-family: 'OpenSansRegular'!important;
  font-weight: 600;
  padding-left: 0;
}
.p-multiselect .p-multiselect-panel{max-width: 300px;}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item{
  max-width: 100%;
  white-space: break-spaces;
}


/****Custom Tabs*****/
.leftTabsbtn{
  position: absolute;
  top:0;
  left: 15px;
  background: url(src/assets/images/right-arrow-angle.png) no-repeat center;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  background-size: 37%;
  box-shadow: rgb(0 0 0 / 15%) -3px 0px 3.2px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.RightTabsbtn{
  position: absolute;
  top: 0;
  right: 15px;
  background: url(src/assets/images/right-arrow-angle.png) no-repeat center;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  background-size: 37%;
  box-shadow: rgb(0 0 0 / 15%) -4px 1px 3.2px;
}

.custom-tabs{
  background-color: #fff;
  overflow: hidden;
  position: relative;
}
.custom-tabs .booking-tabs-menu .p-tabmenu .p-tabmenu-nav .p-tabmenuitem a> span + span{
  position: absolute; top: 0; left: 50%; transform: translate(-50%, -50%);
}
.custom-tabs > .p-tabmenu{
  border-bottom: 1px solid rgba(0,0,0,.12);
  margin-left: 15px;
  overflow-y: hidden;
}
.custom-tabs > .p-tabmenu > ul{
  display: flex; 
  flex-wrap: nowrap; 
  transform: translateX(0);
  transition: transform 500ms cubic-bezier(0.35, 0, 0.25,);}

.custom-tabs > .p-tabmenu > ul > li{
  font-family: 'OpenSansRegular' !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
  box-shadow: none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link{
  background: transparent;
  border: none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link{
  color: #004169;
  font-weight: 600;
  font-family: 'OpenSansRegular';
  background: transparent;
  border: none;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link{
  font-size: 14px;
  font-weight: normal;
  background: transparent;
  border: none;
  height: 35px;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:after{
  content: '';
  width:0;
  height: 2px;
  background-color: #004169;
  position: absolute;
  bottom: 0;
  left: 0;
  transition:all .2s linear;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link:after{
  content: '';
  width:100%;
  height: 2.5px;
  background-color: #004169;
  position: absolute;
  bottom: 0;
  left: 0;
  transition:all .2s linear;
}

.p-multi-error{
  display: block;
  height: 15px;
  color: #f44336;
  font-size: 75%;
  font-family: 'OpenSansRegular' !important;
}


/****Client Type Design Screen***/
.owner-detele-button{
  position: absolute;
  top: 14px;
  left: 185px;
}

.pricelist-items{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
}
.pricelist-items > input{
  width:70px;
}
/* .file-blog{min-width: max-content !important;} */
.file-blog{overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 40px !important;
  display: block;
  text-align: center;}

.inventory-case .col-md-12 + .row{
  display: block;
}
.uploading-files{clear: left;}
.uploading-files > li > input[type="file"],
.uploading-files > li > a{
  cursor: pointer;
}


.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -3px !important;
  font-size: 12px !important;
  left: 0;
}

.test-title {
  font-weight: 600 !important;
  font-family: 'OpenSansRegular' !important;
}

.custom-close-btn-popup{
  position: absolute;
  top:0;
  right:0;
}
.shw-rside-5 {
  width: calc(100% - 5%)!important;
  padding: 0 !important;
}
.shw-rside-20 {
  width: calc(100% - 20%)!important;
  padding: 0 !important;
}

.shw-rside-30 {
  width: calc(100% - 30%)!important;
  padding: 0 !important;
}
.shw-rside-40 {
  width: calc(100% - 40%)!important;
  padding: 0 !important;
}
.shw-rside-50 {
  width: calc(100% - 50%)!important;
  padding: 0 !important;
}
.shw-rside-60 {
  width: calc(100% - 60%)!important;
  padding: 0 !important;
}

.shw-rside-full {
  width: calc(100% - 260px)!important;
  padding: 0 !important;
}

.shw-rside-10 {
  width: calc(100% - 10%)!important;
  padding: 0 !important;
}

.shw-rside-05 {
  width: calc(100% - 5%)!important;
  padding: 0 !important;
}



.p-toast-icon-close:focus{
  outline: none !important;
  box-shadow: none !important;
}

.data-not-found{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
.data-not-found > p{
  color: #a1a1a1;
  font-size: 18px;
  font-family: 'OpenSansRegular';
  margin: 40px 0 0;
}
.custom-checkbox > label{margin-bottom: 0;}


/**Lab Operations Module***/
.element-input-area .element-input{align-items: baseline;}
.element-input-area .element-input >label{width: 100px;}
.element-input-area .element-input >input{border-bottom: 1px solid #B6B6B6; padding-bottom: 4px;}
.action2{min-width: 90px;}
.action2 > i{position: absolute; right: 12px;}

.view-batch-sidebar table tr th:nth-child(5){min-width: 150px;}
.view-batch-sidebar table tr th:nth-child(8),
.view-batch-sidebar table tr th:nth-child(11){min-width: 120px;}

:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >th,
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >td{white-space: nowrap; min-width: 120px;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >th:last-child,
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >td:last-child{min-width: 50px;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >td .indicaor-col{display: flex; align-items: center; justify-content: space-between;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >td .indicaor-col p{margin: 0;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >th:nth-child(6),
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >th:nth-child(7),
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >td:nth-child(6),
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr >td:nth-child(7){min-width: auto;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.registered-patient{border-left: 2px solid #81c4ff;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.test-booking{border-left: 2px solid #0485f7;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.sample-collected{border-left: 2px solid #f3973a;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.sample-processing{border-left: 2px solid #db730a;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.psn-col{border-left: 2px solid #ff6a6a;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.tnp-col{border-left: 2px solid #000000;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.tnp-requested{border-left: 2px solid #a6a6a6;}
:host ::ng-deep .patient-booking .p-datatable-wrapper table tr.report-available{border-left: 2px solid #0e9521;}
:host ::ng-deep .popup-screen-history-second{
    width: calc(100% - 23%);
    padding: 0 ;
}

/*------CSS for patient reg and result entry grid status colors------*/
.sample-collected-border{border-left: 2px solid #f3973a;}
.test-booked-border{border-left: 2px solid #0485f7;}
.sample-processing-border{border-left: 2px solid #db730a;}
.psn-border{border-left: 2px solid #ff6a6a;}
.tnp-border{border-left: 2px solid #000000;}
.tnp-requested-border{border-left: 2px solid #a6a6a6;}
.registered-border{border-left: 2px solid  #81c4ff;}
.report-avialble{border-left: 2px solid #0e9521;}
.test-cancelled{border-left: 2px solid red;}


.sbrea-border{border-left: 2px solid #fdb164;}
.sbrec-border{border-left: 2px solid #db730a;}
.rep-sample-border{border-left: 2px solid #b37125;}
.rev-result-border{border-left: 2px solid #41DFE5;}
.res-mod-border{border-left: 2px solid #ca5fe2;}
.rsfc-border{border-left: 2px solid #ff6a6a;}
.sbrc-border{border-left: 2px solid #fdb164;}
.result-authtcted-border{border-left: 2px solid #61d423;}
.scheduledleadborder{border-left: 2px solid #82D027;}
.rescheduledleadborder{border-left: 2px solid #51D2FF;}
.rejectborder{border-left: 2px solid #923333;}
.test-cancelborder{border-left: 2px solid #c74cc4;}
.pro-rep-border{border-left: 2px solid #8ed096;}

.test-cancelmark{width: 8px;
  height: 8px;
  background-color:#c74cc4;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.rejectmark{width: 8px;
  height: 8px;
  background-color:#923333;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

  .pro-rep-mark{width: 8px;
  height: 8px;
  background-color:#8ed096;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.scheduledlead{width: 8px;
  height: 8px;
  background-color:#82D027;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

  .rescheduledlead{width: 8px;
    height: 8px;
    background-color:#51D2FF;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;}

.result-authtcted-mark{width: 8px;
  height: 8px;
  background-color:#61d423;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.rsfc-mark{width: 8px;
  height: 8px;
  background-color:#ff6a6a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.sbrc-mark{ width: 8px;
  height: 8px;
  background-color:#fdb164;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.rsfc-mark{
  width: 8px;
  height: 8px;
  background-color:#ff6a6a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.sbrec-mark{
  width: 8px;
  height: 8px;
  background-color:#db730a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.res-mod-mark{
  width: 8px;
  height: 8px;
  background-color:#ca5fe2;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.rev-result-mark{ width: 8px;
  height: 8px;
  background-color: #41DFE5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.rep-sample-mark{
  width: 8px;
  height: 8px;
  background-color: #b37125;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;}

.sbrea-mark{
  width: 8px;
  height: 8px;
  background-color: #fdb164;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.rsfc-mark-y{
  width: 8px;
  height: 8px;
  background-color: #ffcb08;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.mark-grey{
  width: 8px;
  height: 8px;
  background-color: #999997;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.test-cancelled-mark{
  width: 8px;
  height: 8px;
  background-color:red;;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.sample-collected-mark{
  width: 8px;
  height: 8px;
  background-color: #eac354;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.test-booked-mark{
  width: 8px;
  height: 8px;
  background-color: #0485f7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.sample_deliveredlab{
  width: 8px;
  height: 8px;
  background-color: #42bad0;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.sample-processing-mark{
  width: 8px;
  height: 8px;
  background-color:#db730a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.tnp-mark{
  width: 8px;
  height: 8px;
  background-color:#000000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.tnp-requested-mark{
  width: 8px;
  height: 8px;
  background-color:#a6a6a6;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.psn-mark{
  width: 8px;
  height: 8px;
  background-color:#ff6a6a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.registered-mark{
  width: 8px;
  height: 8px;
  background-color: #81c4ff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.reports-avialble{
  width: 8px;
  height: 8px;
  background-color: #0e9521;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/*---------------------------------------------*/


.keys-indicators{
    padding-left: 0;
    list-style-type: none;
    width: 135px;
    float: right;
}
.keys-indicators > li:hover > ul{
    display: block;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    width: 165px;
    right: 5px;
}
.keys-indicators ul{
    padding-left: 0;
    display: none;
    position: absolute;
    width: 135px;
    z-index: 1;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 4px 0 rgba(118, 118, 118, 0.5);
}
.keys-indicators li{
    list-style-type: none;
    position: relative;
    cursor: default;
    color: #121212;
    font-size: 10px;
}
.keys-indicators li ul > li{font-style: italic;}
.keys-indicators > li{
    font-size: 12px;
}

.accepted-col{
  border-left: 2px solid #81c4ff;
}
.sent-department-col{
  border-left: 2px solid #0485f7;
}
.performing-location-col{
  border-left: 2px solid #fdb164;
}
.in-bound-query-col{
  border-left: 2px solid #ca5fe2;
}
.psn-col{
  border-left: 2px solid #ff6a6a;
}
.recieved-col{
  border-left: 2px solid #009300;
}
.closed-col{
  border-left: 2px solid #000000;
}
.accepted-ico{
  width: 8px;
  height: 8px;
  background-color: #81c4ff;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.send-dept-ico{
  width: 8px;
  height: 8px;
  background-color: #0485f7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.performing-location-ico{
  width: 8px;
  height: 8px;
  background-color: #fdb164;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.in-bound-query{
  width: 8px;
  height: 8px;
  background-color: #ca5fe2;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.engaged-confirmed{
  width: 8px;
  height: 8px;
  background-color: #65b224;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.psn-ico{
  width: 8px;
  height: 8px;
  background-color: #ff6a6a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.recieved-ico{
  width: 8px;
  height: 8px;
  background-color: #009300;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.tnp-requested-ico{
  width: 8px;
  height: 8px;
  background-color: #a6a6a6;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.tnp-ico{
  width: 8px;
  height: 8px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.table-bordered .p-datatable .p-datatable-tbody > tr > td{
  border: 1px solid #d5d5d5;
  padding: 5px 15px;
}
.table-bordered .p-datatable .p-datatable-thead > tr{
  height: 32px;
}
.table-bordered .p-datatable .p-datatable-thead > tr > th{
  padding: 5px 15px;
}

.vertical-menu .ps__rail-x, .p-sidebar .ps__rail-x, .ps__rail-y,
.layout-menu .ps__rail-x, .p-sidebar .ps__rail-x, .ps__rail-y{opacity: 1 !important;}
.ps__rail-x, .ps__rail-y{opacity: 1 !important;}
.inbox-outlet .ps--active-x>.ps__rail-x, 
.inbox-outlet .ps--active-y>.ps__rail-y{background-color: #fff !important;}

.vertical-menu .ps .ps__rail-x.ps--clicking, 
.vertical-menu .ps .ps__rail-x:focus, 
.vertical-menu .ps .ps__rail-x:hover, 
.vertical-menu .ps .ps__rail-y.ps--clicking, 
.vertical-menu .ps .ps__rail-y:focus, 
.vertical-menu .ps .ps__rail-y:hover{background-color: transparent !important;}

.layout-menu .ps .ps__rail-x.ps--clicking, 
.layout-menu .ps .ps__rail-x:focus, 
.layout-menu .ps .ps__rail-x:hover, 
.layout-menu .ps .ps__rail-y.ps--clicking, 
.layout-menu .ps .ps__rail-y:focus, 
.layout-menu .ps .ps__rail-y:hover{background-color: transparent !important;}

/*Default Show Perfect scrollbar Track*/
/* p-sidebar perfect-scrollbar{
  height: calc(100vh - 200px)!important;
}
p-sidebar perfect-scrollbar .modal-body{
  height: auto!important;
}
p-sidebar perfect-scrollbar perfect-scrollbar{
  height: auto!important;
}
*/
p-sidebar .ps__thumb-y{right: 8px !important;} 

.vertical-menu .ps__thumb-x, .vertical-menu .ps__thumb-y,
.left-sidebar .ps__thumb-x, .left-sidebar .ps__thumb-y{
  background-color: rgba(255, 255, 255, 0.4) !important;
  /* background-color: rgb(255 255 255 / 70%) !important; */
}
perfect-scrollbar .ps-content{
  margin-bottom: 15px;
}

.scan-btn{
  background-color: #e9f6fc;
  width: 152px;
  height: 32px;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  line-height: 32px;
  color: #004169;
  font-weight: 600;
  font-family: 'OpenSansRegular' !important;
  font-size: 12px;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px; 
}
.scan-btn:hover{
text-decoration: none;
}
.patient-widget-col{
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}
.scan-btn.disabled > img{display: none;}
.scan-btn.disabled {background: url(src/assets/images/disable-scan.svg);
  background-repeat: no-repeat; background-position: 20px 50%; text-align: right; padding-right: 0 !important; padding-left: 30px !important;
  background-color: #dfdfdf !important; color: #707070 !important;}

.patient-widget-col > .patient-info-col{
  float: left;
  width: 180px;
  margin-right: 20px;
  margin-bottom: 20px;
}
/* .patient-widget-col > .patient-info-col:last-child{
  margin-left: 0;
  margin-right: 0;
} */
.element-type-input{width: 100%;}
.element-type-input > label{margin-bottom: 0;}
.element-type-input .mat-form-field{margin-top: -18px;}
.patient-widget-col > .patient-info-col > span{
  display: block;
  font-size: 13px;
  margin-bottom: 5px;
}
.patient-widget-col > .patient-info-col > span:first-child{
  color:#949494;
  font-size: 14px;
}
.patient-widget-col > .patient-info-col > span:nth-child(2){
  font-weight: 600;
  line-height: normal;
}

.timepicker-overlay{
  z-index: 1000000000 !important;
}
.invalid-radio{
  margin-top:-10px;
}
.mat-dialog-container{
  position: relative;
}

/*******************Multi Popup*******************/
.multi-popup-two .p-dialog-title{margin-bottom: 0;}
.multi-popup-two .p-component-overlay{z-index: 10000 !important;}
.list-none{list-style-type:none !important;}
.view-remaks-data li{padding: 5px 0; font-size: 13px;}
.view-remaks-data .date-time-track > span{margin-right: 5px;}
.view-remaks-data .date-time-track > span:nth-child(1){font-weight: 600; font-size: 13px;}



/******************Common Report Design****************************/
.app-report{
  padding-left: 20px;
  padding-right: 20px;
}
.report-header{
  background-color: #f6f6f6;
  padding: 15px 0;
  width: 100%;
}
.address-content{
  color: #004169;
}
.address-content p{
  margin-bottom: 0;
}
.client-info-widget{
  position: relative;
}
.client-info-widget:after{
  content: '';
  width: 100%;
  height: 2px;
  background-color: #004169;
  display: block;
}
.client-info-widget h4{
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 5px;
}
.client-name{
  font-size: 13px;
  font-weight: 600;
}
.info-first{
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.info-first > li >span:nth-child(1){
  width: 135px;
  float: left;
  position: relative;
}
.info-first > li >span:nth-child(1):after{
  content:':';
  position: absolute;
  right: 13px;
  font-weight: 600;
}
.info-first > li{
  margin-bottom: 5px;
  float: left;
  width: 100%;
}

.info-second{
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.info-second > li >span:nth-child(1){
  width: 168px;
  float: left;
  position: relative;
}
.info-second > li >span:nth-child(1):after{
  content:':';
  position: absolute;
  right: 13px;
  font-weight: 600;
}
.info-second > li{
  margin-bottom: 5px;
  float: left;
  width: 100%;
}
.listing-data-col ul{
  padding: 0;
  margin: 0;
  padding-left: 15px;
}
.numeric-list{
  list-style-type: auto;
  padding-left: 15px !important;
}
.numeric-list > li{
  margin-bottom: 15px;
}
.reportor-title{
  color: #c5c5c5;
  font-size: 16px;
}

.document-required .files-up .border-none{
  height: 96px !important;
  border: 1px solid #eaeaea !important;
  float: left;
}
.document-required .custom-drop-box{
  max-width: 80%;
}
.document-required .files-up .custom-drop-rreview{
  min-height: 72px !important;
  margin: 0 !important;
  max-height: 72px;
  min-width: 120px !important;
}
.clear-both{
  clear: left;
  float: left;
}
.document-required .custom-drop-box .add-more-file,
.document-required .custom-drop-rreview .trim-text + .ng-star-inserted{
  pointer-events: visible;
  background: #004169!important;
  opacity: 1;
  top: -16px !important;
  right: -5px;
  width: 18px;
  height: 18px;
}
.document-required .custom-drop-rreview .trim-text + .ng-star-inserted > svg{
  transform: scale(.8);
}
.test-sample-col{
  padding-bottom: 30px;
}
.generate_num{
  position: absolute;
  right: 0;
  background-color: #fff;
  padding-left: 8px;
  height: 28px;
  top: 0;
  padding-top: 8px;
  color: #004169;
  font-weight: 600;
  font-size: 12px;
}
.generate_num:hover{
  text-decoration: none;
}
.billing-list-first >li > span:nth-child(1),
.billing-list-second >li > span:nth-child(1){
  margin-right: 8px;
}
.billing-list-first >li > span:nth-child(2),
.billing-list-second >li > span:nth-child(2){
  min-width: 100px;
  text-align: right;
}

.global-search-tracking ul{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.tracking-details{width: 230px; overflow-x: hidden; min-height: 180px;}
.tracking-details h4{margin: 15px 15px 10px; font-weight: 600;}
.tracking-details ul{
  padding:0 ;
  margin: 0;
  padding-left: 10px;
}
.tracking-details ul > li {margin-bottom: 15px; position: relative;}
/* .tracking-details ul > li:last-child{margin-bottom: 20px;} */

.tracking-details ul > li:only-child:before,
.tracking-details ul > li:last-child:before{
  content: none !important;
}
.tracking-details ul > li{
  background: url(src/assets/images/check-arrow.svg) no-repeat #ffffff;
  display: block;
  float: left;
}
.tracking-details ul > li{position: relative; padding-left: 30px;}
.tracking-details ul > li > strong{font-size: 13px;}
.tracking-details ul > li.filled:before{
  content: '';
  position: absolute;
  border-left: dashed 1px #048e17;
  top: 21px;
  left: 11px;
  height: 100%
}
.tracking-details ul > li.no-filled:before{
  content: '';
  position: absolute;
  border-left: dashed 1px #048e17;
  top: 20px;
  left: 11px;
  height: 45px;
}
.in-transit{
  margin-top: 10px;
  display: block;
  color: #686868;
  font-style: italic;
}
.td-break{white-space: normal !important; max-width: 250px;}
.td-break-350{white-space: normal !important; max-width: 350px;}
.pull-left{float: left !important;}
.multiselect-label label{left: 0;}
.multiselect-label > .ng-invalid.ng-dirty + label{color: rgba(0,0,0,.55) !important; margin-top: -15px;}
.receipt-logo{width: 250px;}
.age-unit{width: 100%; float: left; margin-top: -17px;}
.address-col-first ul > li:nth-child(2),
.address-col-first ul > li:nth-child(3){font-weight: 600;}
.final-pay ul li{font-weight: 600;}


.p-sidebar-close-icon:hover{
  border-color: #f1f1f1;
  background-color: #f1f1f1;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition-duration: 0.1s;
}

/***********************Barcode Design CSS********************************************/
.barcode-col{
  position: relative;
}
.barcode-text{
  position: absolute;
  bottom: 5px;
  left: 8px;
  font-weight: 600;
  display: none !important;
}
/* .barcode > svg{transform: translate(0,-20px);} */
/* .barcode > svg g > text{
  font-size: 22px !important; font-weight: 600 !important; color: #333333;
} */
/* .barcode > svg{transform: translate(0,-20px) !important;} */
ngx-barcode.final-barcode{zoom: 50%;}

.ckEditorLabel{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  position: relative;
  height: 25px;
  width: 120px;
}
.ckEditorLabel::after{
  content: '';
  background-color: #ccc;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -1px;
}




/*******************Lead Management Module*************************/
.manage-lead .p-datatable-wrapper table tr >th,
.manage-lead .p-datatable-wrapper table tr >td{white-space: nowrap; min-width: auto;}
.manage-lead .p-datatable-wrapper table tr >th:first-child,
.manage-lead .p-datatable-wrapper table tr >td:first-child{min-width: 50px;}

.call-icon{margin-right: 10px;}
.call-icon > img{width: 58px; height: 24px;}
.bordered-tbl .p-datatable .p-datatable-tbody > tr > td{
  border: 1px solid #d5d5d5;
  padding: 5px 15px;
}
.bordered-tbl .p-datatable .p-datatable-thead > tr{
  height: 32px;
}
.bordered-tbl .p-datatable .p-datatable-thead > tr > th{
  padding: 5px 15px;
  background-color: #cfcece;
}
.reminder-dialog .p-dialog-header >span{margin-bottom: 0;}
.reminder-dialog .p-dialog-header{padding-bottom: 0;}
.reminder-dialog .p-dialog-content{padding-bottom: 0;}
.amount-text{
  color: rgba(0, 0, 0, 0.55);
  font-family: 'OpenSansRegular';
 }
 .amount-text > span{
   color:#000000;
   font-size: 14px;
   font-weight: 600;
 }
 .collection_charges > p:nth-child(1){font-size: 15px; color: #000000; margin-bottom: 0;}
 .collection_charges > p:nth-child(1) > span{font-size: 14px; font-weight: 600; font-family: 'OpenSansRegular' !important;}
 .collection_charges > p:nth-child(4){color: #868686; font-size: 12px;}

 .mark-sample{color: #212529;}
 .mark-sample:hover{color: #212529; text-decoration: none;}
 .mark-sample > img{margin-top: -.25rem;}

 .more-list{
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  font-size: 12px;
  list-style-type: none;
 }
 /* .result_processing .top-header h1{margin-bottom: 10px;} */
 .common-table.small-table .p-datatable .p-datatable-tbody > tr > td div.d-flex{align-items: center;}
 .common-table.small-table .p-datatable .p-datatable-tbody > tr > td,
 .common-table.small-table .p-datatable .p-datatable-tbody > tr > th{padding: 4px 6px; min-width: auto;}
 .small-table .p-datatable-wrapper table tr >th{min-width: auto !important;}
 .common-table.small-table .p-paginator{margin-top: 0;}
 .result_processing .middle-sec{padding-bottom: 0;}
 .result_processing .more-list li{
   display: flex;
   align-items: baseline;
   padding-left: 5px;
 }
 .result_processing .keys-indicators.w-auto li{
  width: 100%;
  float: left;
  line-height: normal;
  margin-bottom: 7px;
 }
 .result_processing .keys-indicators.w-auto li > span:nth-child(1){
    float: left;
    width: 5%;
    margin-top: 3px;
 }
 .result_processing .keys-indicators.w-auto li > span:nth-child(2){
   float: left;
   width: 90%;
}

 .serum-label{
  background-color: #fff8d9;
  box-sizing: border-box;
  padding: 2px 5px;
}


.rider-img > img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.rider-name-list{
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.rider-name-list span + span{margin-left: 5px;}
.rider-img-list > img{
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.rider-img > img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
}

.th-redio-option{
  clear: left;
  display: block;
}
.th-redio-option .mat-radio-label{margin-bottom: 0;}
.td-select-option{height: 30px;}
.td-select-option .mat-form-field-infix{margin-top: 0; border-top: none;}
.save-cancel-btn-td{padding: 0; margin: 0; list-style-type: none;}
.save-cancel-btn-td > li{display: inline-block;}
.cancel-btn-td{color: #707070; font-size: 13px; font-weight: 600; text-decoration: none;}
.cancel-btn-td:hover{text-decoration: none; color: #707070;}
.th-redio-option .mat-radio-outer-circle,
.th-redio-option .mat-radio-inner-circle{width: 17px; height: 17px;}
.no-checkbox-margin > label{margin-bottom: 0;}
.no-checkbox-margin label{margin-bottom: 0;}
.hidden-x-scroll .ps__rail-x{opacity: 0 !important;}

.address-col-first ul > li:first-child{margin-bottom: 0 !important;}
.booking-tabs-menu .p-tabmenu {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0,0,0,.12);
  overflow-y: hidden !important;
}
.micro_entry .table .thead-light th{white-space: pre;}
.micro_entry .table .thead-light th:nth-child(4),
.micro_entry .table tbody td:nth-child(4){ min-width: 140px; max-width: 140px;}
.table .thead-light th{ background-color: #cfcece !important;
  border-bottom: none;
  padding: 10px;}
.table{font-family:'OpenSansRegular' !important;}
.default-tbl tbody+tbody{border-top: 1px solid #dee2e6 !important;}
.p-toast .p-toast-message.p-toast-message-info{background: #D0E5F6; color: #394E59;}
.p-toast{opacity: 1;}
.custom-input{border-bottom: 1px solid #cbcbcb !important;} 




.billing-print-design .modal-body{
  height: 90vh;
  overflow: scroll;
  overflow-x: hidden;
}
.billing-print-design .p-sidebar-close{display: none;}


/****************TAT Alert*****************/
.tat-alert{
  background-color: #e52b2b !important;
  color: #fff;
}

.tat-alert-brown{
  background-color: #cd7f32 !important;
  color: #fff;
}

/*****************Billing Summary*************************/
.billing-summary-scroll .ps--active-x>.ps__rail-x, 
.billing-summary-scroll .ps--active-y>.ps__rail-y{background-color: transparent !important;}
.billing-summary-scroll .ps__rail-y.ps--clicking .ps__thumb-y, 
.billing-summary-scroll .ps__rail-y:focus>.ps__thumb-y,
.billing-summary-scroll .ps__rail-y:hover>.ps__thumb-y{width: 6px !important;}

.billing-summary-scroll{
  height: 260px;
}
.billing-summary{
  position: relative;
  padding: 10px;
}
.billing-summary-scroll .ps-content{
  padding-left: 0;
  padding-right: 15px;
  padding-bottom: 25px;
}
.billing-summary-scroll .ps-content > ul:last-child{
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #f2f5f7;
  height: 35px;
  margin-bottom: 0;
  padding-right: 15px;
} 

/*************************Test Requiesition Form************************/
.t-req-header{
  /* background-color: #004169; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-top: 0;
  box-sizing: border-box;
  position: relative;
  /* border-bottom: 2px solid #004169; */
}
.trf-logo img{width: 250px;}
.affix-barcode{
  /* background-color: #ffffff; */
  min-width: 180px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: -10px;
}
.form-heading{
  background-color: #ffcc29;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 12px 20px;
  width: 100%;
  float: right;
}
.patient-demographics h4,
.client-demographics h4{
  text-transform: uppercase;
  margin-bottom: 5px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  color: #004169;
  font-family: 'OpenSansRegular' !important;
}

.patient-demographics ul{
  padding: 0;
  list-style-type: none;
}
.patient-demographics ul li{position: relative; float: left; width: 100%; margin-bottom: 30px;
display: flex;}
.patient-demographics ul li > span:nth-child(1){
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.patient-demographics ul li > span.input-data{
  border-bottom: 1px dashed #343434;
  display: inline-block;
  width: 100%;
  margin-left: 10px;
}
.age-data{
  display: flex;
  justify-content: space-between;
}
.age-data > span:nth-child(even){
  border-bottom: 1px dashed #343434;
  width: 60px;
}
.patient-demographics .table td, 
.patient-demographics .table th{
  padding: 5px;
}
html.cdk-global-scrollblock{overflow: hidden;}

/*************************404 Erroe Page**************************/
.error_container
{
  display: flex;
  align-items: center;
  justify-content: center;
	width: 100%;
	height: 100vh;
}
.error_block{
  text-align: center;
}
.error_block h1
{
	font-size: 80px;
	color: #3f3a64;
  font-weight: 600;
}

.error_block h3
{
	font-size: 40px;
	color: #02263b;
  font-family: 'OpenSansRegular' !important;
}

.error_btn
{
	width: 200px;
	height: 45px;
	background: #023655;
	border:none;
	border-radius: 5px !important;
	color: #fff;
	font-size: 18px;
	font-weight: normal;
	margin-top: 30px;
	cursor: pointer;
}
.v-id{margin-top: 5px;}
.v-id > li{padding-left: 4px;}
.barcode-user-details{margin-left: 3px !important; }
.mrp-input{
  width: 120px;
  border: none;
  border-bottom: 2px solid #cbcbcb;
  background-color: transparent;
}
.mrp-input:focus{outline: none;}
.samll-button{
  padding: 3px 15px !important;
}

/* .affix-barcode1 svg > rect{fill: transparent !important;} */
.ndhm-radio-btn .mat-radio-label-content{font-family: 'OpenSansRegular' !important; font-weight: 600;}
.affix-barcode1 .barcode > svg g > text{font: 14px !important;}
.patient-res-tabs .p-tabmenu-nav > li:last-child{display: none;}
.notification-button{border: none; background-color: transparent;}
.notification-widget{
  position: absolute;
  width: 300px;
  height: 280px;
  right:5%;
  top: 55px;
  background-color: #ffffff;
  border-radius: 7px;
  z-index: 1;
  text-align: left;
  box-sizing: border-box;
  padding: 15px;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 15px;
  /* overflow: hidden; */
  padding-bottom: 30px;
  border: 1px solid #cbd0dd;
  -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
.notification-widget::before {
  content: '';
  background-image: url(src/assets/images/triangle-shape.png);
  position: absolute;
  top: -11px !important;
  width: 24px;
  height: 11px;
  left: 57px;
}
.notification-button .mat-badge-content {
  color: #fff;
  background: #023655;
}
.notification-button .mat-icon{color: #ffcb08; animation-name: bell; animation-duration: 2s; animation-iteration-count: infinite;}
/* @keyframes bell {
  0% { transform:translate(0,0) }
  1.78571% { transform:translate(5px,0) }
  3.57143% { transform:translate(0,0) }
  5.35714% { transform:translate(5px,0) }
  7.14286% { transform:translate(0,0) }
  8.92857% { transform:translate(5px,0) }
  10.71429% { transform:translate(0,0) }
  100% { transform:translate(0,0) }
} */

.notification-widget ul li{margin-bottom: 10px;}
.notification-widget ul li > a{color: #000000; text-decoration: none;}
.notification-widget h3{margin-bottom: 8px; color: #000000;}
.notification-widget ul li p{margin-bottom: 0; font-size: 12px;}


/**************Directory of Services**************/
.package-data{min-height: 100px;}
.rate-list li a{
  font-weight: 600 !important;
  font-size: 13px;
  color: #023655;
  text-decoration: none;
}
.search-ico .mat-icon{padding-top: 8px;float: left;}
.health-package-index{-webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);     box-sizing: border-box; padding: 15px; border-radius: 10px; min-height: 200px;}
.text-location{min-width: 100px; background-color: #eee; height: 31px; box-sizing: border-box; padding: 5px; display: flex; align-items: center; justify-content: center; border-top-left-radius: 5px; border-bottom-left-radius: 5px; font-weight: 600;}
.search-packages .mat-icon{width: 1.5em !important; text-align: center;}
.package-name h4{font-weight: 600; color: #023655; width: 80px; float: left; margin: 0;}
.microscop-icon{background-image: url(src/assets/images/microscope_icon.svg); background-repeat: no-repeat; background-position: center center; background-size: 75%; width: 38px; height: 38px; background-color: #eee; display: block; float: left; border-radius: 50%; margin-right: 10px;}
.tat-data >span{width: 32px; height: 32px; background-color: #ffcb08; border-radius: 50%; text-align: center; line-height: 32px; font-weight: 600; font-size: 10px; display: block;}
.package-list-data{width: 90%;}
.package-list-data > li{width: 95%; overflow: hidden; white-space:nowrap; text-overflow: ellipsis; margin-bottom: 5px;}
.package-list-data > li > span,
.package-list-data > li > a{font-weight: 600 !important; font-size: 13px; color: #023655;}
.package-list-data > li > a:hover{text-decoration: none;}
.final-rate-of-test{font-size: 1.1em !important;}
.separator-line{
  position: relative;
}
.separator-line:before{
  content: '';
  border-left: 1px solid #bbbbbb;
  position: absolute;
  height: 16px;
  left: 0;
  top: 8px;
}
.rolling-amount{
  background-color: #f2f5f7;
  padding: 8px 15px;
  border-radius: 4px;
  font-family: 'OpenSansRegular';
  font-weight: 600;
  font-size: 13px;
  height: 40px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
}
.rolling-amount > span{
  margin-left: 15px;
}

.itam-details-list li::before{
  content: none !important;
}
.itam-details-list li{
  width: 150px;
  margin-right: 10px;
}


/*Patient Design*/
/* .pend-ent-table table tbody tr:nth-child(odd){border-left: 3px solid #f00;}
.pend-ent-table table tbody tr:nth-child(even){border-left: 3px solid #c90;}
.pend-ent-table table tbody tr:nth-child(odd) td .mat-tooltip-trigger{border-bottom: 2px solid #f00;}
.pend-ent-table table tbody tr:nth-child(even) td .mat-tooltip-trigger{border-bottom: 2px solid #c90;} */


/*
Custom P Dropdown
*/
.custom-p-dropdown .p-dropdown{
  width: 100%;
  border: none;
  border-bottom: 1.5px solid #cbcbcb;
  border-radius: 0;
}
.custom-p-dropdown .p-dropdown-clear-icon{
  background-color: #fff;
  right: 2px;
  z-index: 1;
  font-size: 12px;
  top: 20px;
}
.custom-p-dropdown .country-item{
  font-size: 14px;
}
.custom-p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
  background-color: #e9f6fc;
  color: #023655;
}
.custom-p-dropdown .p-inputtext:enabled:focus{
  box-shadow: none;
}

/*
Custom P Dropdown
*/
.custom-p-dropdown .p-dropdown{
  width: 100%;
  border: none;
  border-bottom: 1.5px solid #cbcbcb;
  border-radius: 0;
}
.custom-p-dropdown .p-dropdown-clear-icon{
  background-color: #fff;
  right: 2px;
  z-index: 1;
  font-size: 12px;
  top: 20px;
}
.custom-p-dropdown .country-item{
  font-size: 14px;
}
.custom-p-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
  background-color: #e9f6fc;
  color: #023655;
}
.custom-p-dropdown .p-inputtext:enabled:focus{
  box-shadow: none;
}


.mat-tab-body-content{
  overflow-y:hidden !important;
}

.sample-dropdown-col .mat-form-field{width: auto !important;}



/*
Chart Design Style
*/
.element-history-chart{
  width: 100% !important;
}
ngx-dropzone-image-preview img,
ngx-dropzone-preview + img{max-width: 100%;}
.file-upload-group a{
  font-size: 13px;
  margin-top: 0 !important;
}
.file-upload-group a >img{
  width: 14px;
}
.address-col-first ul > li > span{
  min-width: 140px !important;
}
.no-after::after{
  content: none !important;
}




/***********************For Print Design CSS*******************************/
@media print {
  .billing-print-design .modal-body{
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
  }
.billing-print-after-design .row{
  margin-right: 0;
  margin-left: 0;
}
/* .w-98-print{width: 98% !important;} */
.booking-billing-print .top-header,
.booking-billing-print .custom-tabs > .p-tabmenu,
.booking-billing-print .billing-tab-form{display: none !important;}
.booking-billing-print .billing-print-design{position:static; border: none;}

.print-recept-section{margin-top: 1.5rem!important;}
.shw-rside-40{width: calc(100% - 0%)!important;}
.print-footer-none{display: none !important;}
.perfect-scrollbar>.ps.ps--active-y>.ps__rail-y{display: none !important;}
.t-req-header:after{left: 192px; bottom: -20px;}
.test-requiesition-form{height: 100% !important;}
.affix-barcode{position: absolute; right: 0; top:-30px;}
/* .trf_form > .col-md-6, .trf_form > .col-md-6 + .col-md-6{
  width: 50%; float: left;
} */
.t-req-header{margin-top: -25px !important;}
.form-heading{width: 100%; float: left;}
.trf_form{margin: 0 25px;}
.form-heading{border-radius: 0 !important;}
.trf-logo img{margin-left: -10px; margin-top: -5px !important;}


.print_page .header-widget,
.print_page .layout-menu,
.print_page .vertical-menu,
.print_page .modal-footer{display: none !important;}
.print_page .main-wrapper >.inbox-outlet{margin-left: 0 !important;}
.print_page .main-wrapper{padding-top: 0;}
.mt-4p{margin-top: 15px; float: left; width: 100%;}
}

/**********For Print Style**********/
@page { 
  margin: 0mm !important;
}

@media print {

@page {
    size:  auto;
    margin: 10mm !important;
}

  html, body {
    height: 99% !important;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    margin:0;
    padding:0;
    -webkit-print-color-adjust: exact !important;
 }


 .middle-sec .pt-4{padding-top: 0 !important;}
 .result_processing .more-list li{line-height: normal !important;}
 .print-recept-section{padding: 0 !important;}
 .common-table .p-datatable .p-datatable-thead > tr > th{background-color: #cfcece !important;}
.p-sidebar-mask.p-component-overlay{display: none !important;}
.result-entry-page .vertical-menu,
.result-entry-page .left-sidebar,
.header-widget,
.result-entry-page .no-print,
.result_processing .no-print{display: none !important;}
.header-widget{display: none;}
.main-wrapper.result-entry-page >.inbox-outlet{margin-left: 0;}
.shw-rside-20 {
    width: calc(100% - 0%)!important;
}
.result-entry-page .filter-widget,
.result-entry-page .p-tabmenu,
.result-entry-page .common-table,
.result-entry-page .top-header{display: none;}
.result-entry-page .modal-header, .result-entry-page .modal-body{padding: 15px 0px !important;}
.result-entry-page, .result-entry-page .top-header{padding-top: 0 !important;}
.payment_successful_inbox button,
.payment_failed_inbox button{display: none;}



/*************Payment Design For Print Success Dailog**********/
.p-overflow-hidden .top-header,
.p-overflow-hidden .vertical-menu,
.p-overflow-hidden .layout-menu{display: none;}
.p-overflow-hidden .p-dialog-mask{
  position: absolute;
  left: 0 !important;
  top:0;
  width:100%;
  background-color: #000;
}
.p-overflow-hidden .main-wrapper >.inbox-outlet{margin-left: 0;}
.p-overflow-hidden .p-dialog-mask.p-component-overlay{
  background-color: #ffffff;
}
.p-overflow-hidden .p-dialog-content{overflow: hidden;}
.p-overflow-hidden .p-dialog{
  -webkit-box-shadow: none;
  box-shadow: none;
}
.p-overflow-hidden .p-dialog, 
.p-overflow-hidden .p-dialog-mask.p-component-overlay{border: none;}
.p-overflow-hidden .payment-head{width: 99%;}
.p-overflow-hidden .payment_failed_inbox_bg{overflow: hidden;}
.p-overflow-hidden .payment-dialog-box .p-dialog-mask{
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}
/*************Payment Design For Print Success Dailog End**********/


/***********************Barcode Design CSS********************************************/
.barcode-col, #barcodeId{
  position: relative;
}
.barcode-text{
  position: absolute;
  bottom: 5px;
  left: 8px;
  font-weight: 600;
  display: none;
}
/* .barcode > svg{
  -webkit-transform: translate(30px,0);
  -ms-transform: translate(30px,0);
  transform: translate(30px,0);
} */
.barcode > svg g > text{
  font-size: 16px !important; font-weight: 600 !important; color: #333333;
}
.barcode > svg g > rect{
  fill: transparent !important;
}
/* .barcode > svg{transform: translate(0,-10px) !important;} */
.final-barcode{display: none !important;}
.barcode > svg g > text{font-size: 22px !important;}
.v-id >li{top:20px !important; position: absolute; z-index: 2;}
/* .affix-barcode1 .barcode > svg{margin-top: 5px;} */
.barcode_other_dtl{margin-top: 10px;}
.print-mt-2{margin-top: 20px !important;}
.barcode_include_details{margin-top: 20px !important;}

.print-recept-section .common-table thead{display:table-header-group !important;}
.print-recept-three-sec >.col{
  width: 30%;
  float: left;
}
.print-recept-three-sec >.col.address-col-last{width: 40%;}
.col.address-col-last li > span:nth-child(2){max-width: 180px !important;}
}





/******Responsive Screen****/
@media only screen and (max-width: 1920px) {
  .container{
    min-width: 100%;
  }

.element-page-accordion .mat-expansion-panel{width: 100%;}
}

@media only screen and (max-width: 768px) {
/***Mobile Menu**/
/* .left-sidebar, .vertical-menu{display: none;} */
.brand-logo{
  /* padding-left: 50px; */ 
  max-width: 150px;
}
.toggler-btn{
  position: fixed;
  z-index: 4;
  top: 0;
}
.chips-button-set{
  display: block !important;
}
.chips-button-set > button{margin-bottom: 4px !important; margin-right: 4px !important;}
.booking-amount-fileds .col-auto,
.booking-amount-fileds .col-auto .rolling-amount,
.booking-amount-fileds .rolling-amount{width: 100%;}
.separator-line:before{content: none !important;}
.define-formula{margin-top: 10px;}
.top-header .row div + div{margin-bottom: 15px;}
/* .top-header .row div + div > button{float: left;} */
.booking-add-buttons{position: static !important; margin: 15px 0;} 
.booking-add-buttons > button:nth-child(1){margin-left: 0 !important;}


.main-wrapper >.inbox-outlet{margin-left: 0;}
.position-end{left: 0; z-index: inherit;}
.common-table .p-datatable table{table-layout: auto;}
.common-table .p-datatable .p-datatable-tbody > tr > td{min-width: 150px;}
.common-table .p-datatable .p-datatable-tbody > tr > td:first-child{min-width: 60px;}

.filter-widget{
  margin-bottom: 20px;
}
/***Dialog Box***/
.p-dialog{
  width: 90vw !important;
}

/****Custom Tabs*****/
.custom-tabs > .p-tabmenu{
  margin-left: 0;
}

.popup-screen{width: 95% !important;}
.shw-rside-30 {
  width: calc(100% - 5%)!important;
}
.shw-rside-40 {
  width: calc(100% - 5%)!important;
}
.shw-rside-50 {
  width: calc(100% - 5%)!important;
}
.shw-rside-60 {
  width: calc(100% - 6%)!important;
}
.shw-rside-full {
  width: calc(100% - 5%)!important;
}
.department-section{overflow-x: scroll;}
.incoming-call-box{ max-width: 320px;}

.call-details-left{width: 100% !important; }
.call-details-right{width: 100% !important; border-left: none !important;}
.user-call-details{position: static !important;}

.current-blance-col{display: block !important;}
.shw-rside-20{width: calc(100% - 5%)!important;}


}

@media only screen and (max-width: 480px) {
  .custom-tabs .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    padding: 15px 10px !important;
  }
  .p-toast-top-right {right: 0 !important;}
  .p-toast {width: 100% !important; top:0 !important;}
  .user-details-col{line-height: 14px; padding-top: 0 !important;}
  .header-widget{height: 40px;}
  .vertical-menu, .left-sidebar{top:40px !important;}
  .mobile-icons{position: absolute !important; right: 0; width: auto !important; top: 47px;}
  .mobile-side-buttons{position: absolute !important; right: 0; width: auto !important; top: 50px;}
  .m-mt-15{margin-top: 15px !important;}
  .patient-res-tabs .p-tabmenu-nav {justify-content: space-between;}
  .rolling-amount{width: 100%; margin-bottom: 6px;}
  }

@media only screen and (max-width: 1024px) {
.common-table{overflow: auto;}
.custom-tabs .p-tabmenu{
  overflow-x: scroll;
  overflow-y: hidden;
}


}



/**After Using N1ED**/
.mat-form-field-appearance-legacy .mat-form-field-underline{
  background-color: rgba(182, 182, 182, 0.7) !important;
}

/* .mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 18px/32px 'Open Sans' !important;
  font-weight: 800 !important;
} */
.mat-select-panel:not([class*=mat-elevation-z]),
.mat-datepicker-content .mat-calendar {
  background-color: #fff !important;
}
.mat-calendar-body-selected{color: #fff !important;}
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #004169 !important;
  background-image: url(src/assets/images/white-check.svg);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #004169 !important;
}
.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.12) !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #004169 !important;
}
.mat-checkbox-frame {
  border-width: 2px !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-outer-circle {
  border-color: #004169 !important;
}
.mat-option-text ul{margin-bottom: 0 !important;}
.font-f-os{font-family: 'OpenSansRegular' !important;}




.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 18px/32px 'Open Sans' !important;
  font-weight: 800 !important;
}
.mat-select-panel:not([class*=mat-elevation-z]),
.mat-datepicker-content .mat-calendar {
  background-color: #fff !important;
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #004169 !important;
  background-image: url(src/assets/images/white-check.svg);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #004169 !important;
}
.mat-tab-nav-bar, .mat-tab-header {
  border-bottom: 1px solid rgba(0,0,0,.12) !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #004169 !important;
}
.mat-checkbox-frame {
  border-width: 2px !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-outer-circle {
  border-color: #004169 !important;
}
.mat-autocomplete-panel,
.mat-dialog-container{ background-color: #fff !important;}

.ps--active-y .ps__thumb-y{opacity: 1 !important;  display: block !important;}
.ps{overflow: inherit !important;}

.cke_notifications_area {
  display: none !important;
}


.criticalrefrange {
  background-color: red !important;
}

.psn-doc {
  background-color: #007ad9 !important;
}

.td-width-10 {
    width: 10%
}

.td-width-20 {
  width: 15%
}

.td-width-30{
  width: 20%
}

.td-width-25{
  width: 25%
}

.innerhtml-class {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    width: 300px;
    max-height: 25px;
}


/* common css: 12-02-2025 */
.rotate180{
  rotate: 180deg
}
.closeBtn{
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 2;
}
.btn-xxs{
  padding: 3px 5px;
  font-size: 11px;
  line-height: 1;
}
.btn-xxs:focus,.btn-xxs:hover{
  text-decoration: none;
}
/* Upload Docs */
.docsUploadContainer {
  position: fixed;
  right: 0;
  top: 60px;
  bottom: 0;
  width: 260px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  border-radius: 5px;
  z-index: 99;
}
.docsUpload{
  padding: 15px 5px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.req-icon .mat-form-field-subscript-wrapper{
  position: absolute;
  width: 16px;
  height: 16px;
  text-align: center;
  right: -5px;
  line-height: 1.3;
  margin-top: 2px !important;
}
.criticalrefrange .req-icon .fa-info-circle{
  color:#fff !important
}
.req-icon .mat-form-field-subscript-wrapper .mat-form-field-hint-wrapper{
  display: block !important;
}
.req-icon .mat-form-field-subscript-wrapper .mat-hint{
  height: 15px;
}

.td-custum-width {
  width: 1rem !important;
}

/* booking responsive */
.keys-indicators{width: auto !important;}
@media only screen and (max-width: 1366px){
  .filter-widget .save-button,.reset-button{padding: 5px 8px !important}
}
@media only screen and (max-width: 1280px){
  ::ng-deep .p-datatable .p-datatable-tbody > tr > td,
  ::ng-deep .common-table .p-datatable .p-datatable-thead > tr > th{ padding: 7px !important; white-space: nowrap;}
  .save-button{padding: 5px 8px !important}
}

/* responsive booking */
@media only screen and (max-width: 800px){
  .fw-footer{width: 100% !important;}
  .tab-table .p-datatable .p-datatable-tbody > tr > td{
    min-width: auto;
  }
  .upload-file-list{
    flex-wrap: wrap;
  }
  .shw-rside-40, .popup-screen-history-third, .shw-rside-20, .popup-screen-history-second, .shw-rside-30, .shw-rside-50{
    width: calc(100% - 20px)!important;
  }
}
@media only screen and (max-width: 576px) {
  .billing-print-design .modal-body{
    overflow-x: scroll;
  }
  .print-recept-section{min-width: 710px;}
}

.td-custum-width {
  width: 1rem !important;
}


/* cstm pagination */
.pagination-controls {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: Arial, sans-serif;
  margin-top: 1rem;
}

.pagination-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  color: #555;
}

.pagination-icon:hover {
  background-color: #f0f0f0;
}

.pagination-icon.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}

.page-label {
  font-weight: 500;
  font-size: 14px;
  color: #333;
}

.rows-per-page {
 margin-left: -15px;
}

:host ::ng-deep p-dropdown .p-dropdown-trigger {
  width: 1.5rem !important;
}
